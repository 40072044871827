export default function () {
  return (
    <>
      <svg
        width="289"
        height="288"
        viewBox="0 0 289 288"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.3" cx="145" cy="144" r="144" fill="white" />
        <g clipPath="url(#clip0_8_5133)">
          <path
            opacity="0.27"
            d="M1.86737 227.122C1.86737 256.546 66.3057 283.482 145.348 281.94C235.18 280.187 288.033 254.356 288.033 224.931C288.033 195.507 224.153 176.698 145.124 176.698C66.0953 176.698 1.86737 197.691 1.86737 227.122Z"
            fill="black"
          />
          <path
            d="M0 223.992C0 253.416 64.4384 280.352 143.481 278.81C233.313 277.056 286.165 251.225 286.165 221.801C286.165 192.376 222.247 173.568 143.224 173.568C64.2016 173.568 0 194.567 0 223.992Z"
            fill="#B22F5D"
          />
          <path
            d="M0.124939 220.702C0.124939 250.133 61.1507 273.138 140.167 273.138C219.182 273.138 286.323 250.166 286.323 220.735C286.323 191.304 222.253 167.459 143.224 167.459C64.1951 167.459 0.124939 191.278 0.124939 220.702Z"
            fill="url(#paint0_linear_8_5133)"
          />
          <path
            d="M4.97754 220.676C4.97754 247.903 63.7808 269.167 139.91 269.167C216.039 269.167 280.767 247.903 280.767 220.676C280.767 193.449 219.038 171.377 142.889 171.377C66.7397 171.377 4.97754 193.449 4.97754 220.676Z"
            fill="#B22F5D"
          />
          <path
            d="M10.3233 221.913C10.3233 247.625 66.9962 267.705 140.41 267.705C213.824 267.705 276.164 247.625 276.164 221.913C276.164 196.202 216.651 175.361 143.231 175.361C69.8104 175.361 10.3233 196.202 10.3233 221.913Z"
            fill="url(#paint1_linear_8_5133)"
          />
          <path
            d="M43.1277 226.698L49.1376 241.305L85.8411 252.576L126.273 256.54L155.303 254.66L174.168 249.445L196.767 240.676L43.5419 211.463L43.1277 226.698Z"
            fill="#914004"
          />
          <path
            d="M25.0915 226.698C25.0915 226.698 24.2564 226.281 31.726 224.607C39.1956 222.933 30.8909 219.187 32.9687 211.668C35.0466 204.15 50.8011 201.662 61.5781 203.323C72.355 204.984 63.2416 223.773 63.2416 223.773L49.5518 233.376L25.0915 226.698Z"
            fill="#914004"
          />
          <path
            d="M44.9951 209.167C44.9951 209.167 43.3315 214.177 45.4093 224.19C47.4871 234.203 60.7496 242.555 71.9474 244.23C83.1452 245.904 113.004 250.908 128.758 250.908C144.513 250.908 166.08 248.816 177.692 246.315C189.304 243.813 220.82 235.461 227.868 234.21C234.917 232.959 248.193 225.447 251.093 222.959C253.992 220.471 254.827 210.02 252.335 207.519C249.843 205.017 229.841 209.081 229.841 209.081L44.9951 209.167Z"
            fill="#FFEDC4"
          />
          <path
            d="M69.771 197.373C69.771 197.373 54.4307 197.79 46.9611 205.315C39.4915 212.84 47.7896 229.729 70.6323 235.573C93.4751 241.417 148.386 243.085 167.461 239.954C186.536 236.824 219.505 228.042 227.592 225.765C235.68 223.488 258.496 212.41 251.231 202.807C243.965 193.204 69.771 197.373 69.771 197.373Z"
            fill="url(#paint2_linear_8_5133)"
          />
          <path
            d="M61.8937 196.367C61.8937 196.367 60.96 198.869 66.2466 203.568C71.5331 208.267 77.4247 209.2 82.731 209.829C88.0373 210.457 96.4142 218.591 111.34 219.842C126.266 221.093 144 223.283 174.464 224.852C204.927 226.42 230.137 212.297 237.304 211.046C244.471 209.796 255.058 203.846 255.965 199.134C256.872 194.422 255.34 189.12 255.34 189.12L61.8937 196.367Z"
            fill="#FFEDC4"
          />
          <path
            d="M131.974 213.442C131.974 213.442 148.149 214.488 163.286 215.95C178.422 217.413 201.422 219.081 229.841 209.061C258.26 199.041 262.599 185.48 245.181 181.099C227.763 176.718 109.374 209.895 109.374 209.895C109.374 209.895 116.219 213.442 131.974 213.442Z"
            fill="#E2952C"
          />
          <path
            opacity="0.18"
            d="M58.1589 158.869C55.7033 161.512 53.1089 164.022 50.3868 166.387C46.0274 170.139 54.1151 170.556 56.3047 179.008C58.4942 187.459 56.8373 190.921 62.0581 196.017C69.9485 203.74 81.1266 204.839 92.5414 209.008C103.417 212.979 120.085 217.221 135.281 216.367C159.432 215.011 173.74 217.83 183.071 216.262C192.401 214.693 201.62 209.643 210.233 206.817C222.674 202.747 228.178 198.062 235.739 192.992C253.493 181.079 246.917 175.46 246.917 172.019C246.917 168.578 241.322 171.357 232.616 166.063C223.91 160.768 58.1589 158.869 58.1589 158.869Z"
            fill="black"
          />
          <path
            d="M58.1589 157.83C55.7022 160.47 53.1079 162.978 50.3869 165.341C46.0274 169.1 42.6082 171.913 44.7847 180.365C46.9611 188.816 54.4307 191.615 60.0263 196.334C65.6219 201.053 83.9737 212.926 95.4806 214.203C106.987 215.481 117.252 217.016 132.493 217.334C147.735 217.651 171.058 215.454 180.388 213.886C189.718 212.317 203.5 207.003 212.114 204.183C224.555 200.113 228.191 197.049 235.785 191.973C253.539 180.06 246.963 174.448 246.963 171C246.963 167.552 241.368 170.378 232.662 165.043C223.956 159.709 58.1589 157.83 58.1589 157.83Z"
            fill="#FFEDC4"
          />
          <path
            d="M60.0263 156.473C60.0263 156.473 46.7573 161.483 44.7123 171.291C42.6674 181.099 57.7775 191.324 67.9364 193.826C78.0953 196.328 87.84 205.739 104.43 208.856C121.019 211.973 144.033 210.729 164.765 209.69C185.497 208.651 208.307 201.748 221.162 195.077C234.016 188.406 248.535 185.48 247.496 173.376C246.457 161.271 232.984 161.059 232.984 161.059L60.0263 156.473Z"
            fill="url(#paint3_linear_8_5133)"
          />
          <path
            opacity="0.18"
            d="M52.6685 160.543C52.6685 160.543 54.1151 170.556 72.5721 175.983C91.0291 181.41 109.683 182.35 118.185 181.94C126.687 181.529 140.265 182.773 153.33 182.562C166.396 182.35 192.829 174.739 198.01 174.739C203.191 174.739 223.726 169.941 230.775 169.107C237.824 168.273 241.953 163.68 240.105 162.634C238.258 161.589 211.285 167.016 211.285 167.016L160.274 174.527L95.5858 173.905L63.8598 167.433L52.6817 160.55"
            fill="black"
          />
          <path
            d="M50.3869 150.781C50.3869 150.781 49.7622 159.239 55.7787 164.09C58.3036 166.129 73.6044 177.863 100.978 177.446C128.351 177.029 148.248 180.365 171.886 176.195C195.524 172.026 215.014 169.1 227.454 166.585C239.895 164.071 252.75 156.572 250.678 145.301C248.607 134.031 50.3869 150.781 50.3869 150.781Z"
            fill="#FFEDC4"
          />
          <path
            d="M61.6833 135.599C61.6833 135.599 50.5052 137.274 50.0713 148.121C49.6373 158.968 70.8099 168.571 94.0274 170.245C117.245 171.92 137.99 172.33 162.871 169.828C187.752 167.327 211.384 165.652 230.045 158.975C248.706 152.297 257.832 141.443 242.9 140.609C227.967 139.775 61.6833 135.599 61.6833 135.599Z"
            fill="#E2802C"
          />
          <path
            d="M52.5633 126.42C52.5633 126.42 49.7622 129.861 54.1151 139.252C58.4679 148.643 74.6433 153.342 81.4882 155.533C88.3331 157.724 109.479 161.165 121.295 165.235C133.111 169.305 144.625 167.426 154.573 165.864C164.522 164.302 182.255 160.569 190.961 159.603C199.667 158.637 222.378 157.412 238.547 145.831C254.716 134.249 254.098 124.229 253.151 119.848C252.204 115.466 52.5633 126.42 52.5633 126.42Z"
            fill="#FFEDC4"
          />
          <path
            d="M58.7835 110.139C58.7835 110.139 47.6055 121.39 54.2203 136.016C60.8351 150.642 82.0011 149.378 101.497 156.473C120.993 163.568 153.33 160.629 171.991 157.32C190.652 154.011 208.07 158.154 233.799 143.131C259.529 128.107 255.36 112.687 245.826 112.687C236.291 112.687 58.7835 110.139 58.7835 110.139Z"
            fill="url(#paint4_radial_8_5133)"
          />
          <path
            d="M49.7622 108.266C49.7622 108.266 47.5858 114.527 52.2477 118.908C56.9096 123.289 77.1288 131.112 86.4395 136.115C95.7502 141.119 125.01 148.637 156.73 144.256C188.449 139.874 205.558 137.995 214.593 137.055C223.627 136.115 236.673 134.236 245.694 127.353C249.055 124.806 251.846 121.576 253.885 117.873C255.924 114.169 257.166 110.075 257.53 105.857C257.839 100.847 227.98 103.038 227.98 103.038L49.7885 108.259"
            fill="#FFEDC4"
          />
          <path
            d="M71.2241 85.5127C71.2241 85.5127 50.4854 87.604 49.657 103.878C48.8285 120.152 68.7255 121.827 82.8361 128.087C96.9468 134.348 134.255 140.192 154.159 138.524C174.062 136.857 194.801 131.43 215.967 130.583C237.133 129.735 244.583 117.644 251.217 113.468C257.852 109.292 260.752 103.865 251.632 97.1872C242.512 90.5094 71.2241 85.5127 71.2241 85.5127Z"
            fill="url(#paint5_radial_8_5133)"
          />
          <path
            d="M50.8011 217.546C50.8011 217.546 47.8948 210.451 56.6071 209.604C65.3194 208.757 71.1189 212.913 78.1677 213.78C85.2164 214.647 101.392 218.783 111.34 219.829C121.289 220.874 148.248 223.376 148.248 223.376C148.248 223.376 153.225 227.135 141.613 228.386C130.001 229.637 94.343 231.305 91.851 237.149C89.3589 242.992 93.9288 247.585 96.8285 248.836C99.7282 250.087 117.146 251.967 123.787 252.384C130.428 252.801 157.381 251.762 171.064 248.413C184.747 245.064 203.822 239.65 210.043 238.816C210.043 238.816 190.553 245.077 181.433 248.83C172.313 252.582 148.668 260.934 128.765 259.684C108.861 258.433 96 255.931 84.8219 255.077C73.6438 254.223 49.8871 248.942 37.131 243.826C24.6904 238.823 19.3052 232.979 22.6192 227.943C25.9331 222.906 37.5518 227.943 37.966 224.184C38.3803 220.424 44.2981 221.358 45.429 228.571C46.0471 232.542 52.8921 242.549 85.2362 247.559C85.2362 247.559 88.1425 245.891 87.314 241.298C86.4855 236.705 83.1649 238.38 71.1386 234.204C59.1123 230.027 53.2603 226.282 50.8011 217.546Z"
            fill="#914004"
          />
          <path
            d="M130.836 54.7314C138.967 54.7314 145.558 53.6113 145.558 52.2297C145.558 50.8481 138.967 49.728 130.836 49.728C122.705 49.728 116.114 50.8481 116.114 52.2297C116.114 53.6113 122.705 54.7314 130.836 54.7314Z"
            fill="#6C3200"
          />
          <path
            opacity="0.18"
            d="M95.1715 124.699C96.1052 124.957 119.428 134.037 148.353 131.218C177.278 128.398 192.21 120.265 198.431 119.636C204.651 119.007 230.459 110.245 237.304 108.683C244.149 107.121 253.479 101.165 248.193 95.8436C242.906 90.5226 95.1715 124.699 95.1715 124.699Z"
            fill="black"
          />
          <path
            d="M51.1627 98.3519C51.1627 98.3519 51.6296 103.408 53.497 104.818C55.3644 106.228 53.497 107.326 56.4493 109.358C59.4016 111.39 78.8449 120.609 95.1715 124.699C111.498 128.789 130.159 131.43 153.639 127.201C177.12 122.972 186.299 118.438 198.582 115.149C210.865 111.86 227.198 106.386 236.219 104.196C245.241 102.005 246.477 99.3381 247.568 97.4651C248.66 95.5922 248.969 91.3565 247.259 90.4167C245.55 89.477 224.088 96.9952 224.088 96.9952L141.199 101.846L51.1627 98.3519Z"
            fill="#FFEDC4"
          />
          <path
            d="M57.2055 80.7146C57.2055 80.7146 52.5435 82.9052 51.2877 91.3566C50.0318 99.8081 51.5967 103.269 59.9934 106.069C68.3901 108.868 83.9408 117.982 104.778 121.41C125.615 124.838 143.967 122.661 172.583 116.718C201.199 110.774 222.963 101.066 232.918 99.1859C242.873 97.3064 249.087 92.6075 246.911 89.4771C244.734 86.3467 228.25 86.3467 228.25 86.3467L57.2055 80.7146Z"
            fill="url(#paint6_radial_8_5133)"
          />
          <path
            d="M64.537 87.551C64.537 87.551 49.9726 91.4624 51.2153 97.7563C52.4581 104.05 69.8761 109.861 73.1901 111.112C76.5041 112.363 77.3391 118.623 74.4394 118.623C71.5397 118.623 62.8274 114.454 62.4131 118.206C61.9989 121.959 76.7145 124.679 82.8361 128.134C88.9578 131.588 93.5145 138.676 88.537 138.26C83.5594 137.843 65.2602 135.751 67.3315 142.846C69.4027 149.941 79.4104 147.856 91.8509 153.7C104.291 159.544 93.9287 161.642 86.4592 159.961C74.492 157.313 66.5556 165.387 83.5594 168.306C100.563 171.225 103.89 172.853 102.22 177.836C100.55 182.82 83.9737 181.595 78.9961 183.68C74.0186 185.765 74.4394 193.277 86.4592 197.036C98.4789 200.795 103.049 195.368 110.926 198.703C118.803 202.039 129.587 202.879 133.322 197.036C137.056 191.192 122.952 184.931 117.146 182.012C111.34 179.094 103.049 177.002 108.441 173.243C113.832 169.484 122.124 173.243 121.71 168.65C121.295 164.057 108.441 164.481 105.12 161.139C101.799 157.796 103.049 153.627 114.247 154.461C125.444 155.295 127.562 149.424 120.519 146.069C113.477 142.714 100.978 143.197 98.0712 139.861C95.1649 136.526 91.8509 129.007 96.8285 129.007C101.806 129.007 109.013 135.923 111.656 131.979C113.714 128.915 100.563 126.188 95.1715 124.699C89.7797 123.21 80.7846 120.185 80.7846 120.185C80.7846 120.185 76.0964 117.756 79.8246 111.079C83.5528 104.401 94.343 111.496 106.784 106.909C119.224 102.323 107.178 93.4214 92.7123 92.1639C83.2273 91.2726 73.8137 89.7314 64.537 87.551Z"
            fill="#914004"
          />
          <path
            opacity="0.18"
            d="M54.4307 82.7L52.2477 87.0812C52.2477 87.0812 64.0833 96.4724 94.2378 96.1613C124.392 95.8503 132.493 97.7232 140.581 94.5928C148.669 91.4624 174.477 93.931 180.079 93.931C185.681 93.931 214.271 92.0581 223.312 88.9277C232.353 85.7973 54.4307 82.7 54.4307 82.7Z"
            fill="black"
          />
          <path
            d="M43.2329 59.4304C43.2361 60.657 43.445 61.8742 43.851 63.0307C44.5085 64.4338 42.6082 66.631 42.6082 70.2313C42.6082 73.8316 46.0274 78.8349 58.1589 84.9435C70.2904 91.0521 88.3266 92.2963 111.031 93.0773C133.736 93.8582 128.127 91.3565 159.242 90.2645C190.356 89.1725 182.098 93.8648 215.066 90.1057C248.035 86.3466 248.66 81.5021 249.436 80.2446C250.212 78.9872 251.178 74.1426 250.521 72.6072C249.863 71.0718 237.613 68.8547 237.613 68.8547L43.2329 59.4304Z"
            fill="#FFEDC4"
          />
          <path
            d="M45.7184 64.6654C45.7184 64.6654 46.7573 63.4145 44.2652 61.3563C41.7732 59.298 43.851 53.8446 51.3206 48.2126C58.7902 42.5805 89.4576 29.8471 142.133 32.1436C194.808 34.4401 231.912 52.3357 238.547 57.1339C245.181 61.9321 250.159 65.6912 249.955 70.9063C249.955 70.9063 252.441 74.6654 245.392 75.4993C238.343 76.3332 225.902 78.4179 214.29 80.0857C202.678 81.7535 170.124 83.3948 151.259 81.7601C132.395 80.1254 117.462 84.8905 91.542 82.594C65.622 80.2975 50.8537 77.1803 45.7184 70.9063C42.8187 67.359 45.7184 64.6654 45.7184 64.6654Z"
            fill="url(#paint7_linear_8_5133)"
          />
          <path
            d="M126.247 45.7574C126.247 45.7574 96.0789 45.4463 98.5644 51.3166C101.05 57.187 110.696 52.8587 114.424 60.271C118.152 67.6834 89.8586 64.9038 79.5748 64.5927C69.291 64.2817 62.2027 71.1182 64.7671 77.6901C68.1929 86.4592 73.5058 85.0363 69.8762 88.941C69.5145 89.3315 72.7759 89.7749 72.7759 89.7749C72.7759 89.7749 74.4855 88.7028 72.4669 83.4016C70.1129 77.2401 83.6449 74.7582 95.7896 76.6113C107.934 78.4644 145.243 78.7755 156.441 79.391C167.638 80.0065 183.498 81.2441 186.917 77.2268C190.336 73.2096 178.521 76.0157 179.77 70.1454C181.019 64.275 195.005 68.6033 211.798 66.4458C228.592 64.2883 221.444 55.3273 211.798 52.8587C202.152 50.3901 224.239 50.6945 234.812 55.6383C245.385 60.5821 238.856 51.9321 226.415 47.9149C213.975 43.8977 185.359 46.0684 166.698 47.2994C148.037 48.5304 142.757 46.0684 126.247 45.7574Z"
            fill="#914004"
          />
          <path
            opacity="0.18"
            d="M245.701 127.36C236.679 134.242 223.621 136.122 214.599 137.062C205.578 138.002 188.476 139.881 156.736 144.262C124.997 148.644 95.7764 141.132 86.446 136.122C77.977 131.575 60.526 124.706 53.8981 120.199L53.1682 120.265C53.1682 120.265 52.5501 121.933 52.5501 126.42C52.5501 130.907 67.4761 132.786 76.2213 136.539C84.9665 140.291 105.764 146.976 121.315 148.644C136.866 150.311 159.57 149.061 172.945 146.87C186.319 144.679 210.575 140.298 217.111 140.914C223.647 141.529 253.184 137.604 253.184 119.841C253.181 119.638 253.153 119.435 253.098 119.239C251.147 122.378 248.639 125.131 245.701 127.36Z"
            fill="black"
          />
          <path
            opacity="0.18"
            d="M81.4882 155.533C74.6433 153.343 58.4745 148.644 54.1151 139.252C54.1151 139.252 51.6296 140.609 50.3868 144.679C50.3868 144.679 60.3353 153.448 70.5995 155.93C80.8636 158.412 89.5693 161.887 96.7233 159.689C96.8623 159.662 96.9935 159.604 97.1073 159.52C97.2211 159.435 97.3146 159.326 97.3808 159.2C90.6214 157.777 84.5852 156.526 81.4882 155.533Z"
            fill="black"
          />
          <path
            d="M86.137 64.3875C86.1368 64.7929 86.0166 65.189 85.7917 65.5253C85.5667 65.8615 85.2473 66.1226 84.8741 66.2752C84.501 66.4279 84.0911 66.465 83.6969 66.382C83.3027 66.2989 82.942 66.0995 82.661 65.8091C82.38 65.5187 82.1914 65.1505 82.1193 64.7517C82.0472 64.3529 82.0949 63.9415 82.2562 63.57C82.4176 63.1986 82.6854 62.8841 83.0252 62.6666C83.3651 62.4491 83.7617 62.3385 84.1644 62.3491C84.6929 62.3612 85.1955 62.5815 85.5644 62.9627C85.9332 63.3438 86.1388 63.8554 86.137 64.3875Z"
            fill="#D72F5D"
          />
          <path
            d="M101.083 71.5814C102.198 71.5814 103.101 70.6717 103.101 69.5496C103.101 68.4275 102.198 67.5178 101.083 67.5178C99.9679 67.5178 99.0641 68.4275 99.0641 69.5496C99.0641 70.6717 99.9679 71.5814 101.083 71.5814Z"
            fill="#F6605D"
          />
          <path
            d="M107.77 65.7906C107.771 66.194 107.654 66.5888 107.432 66.9248C107.21 67.2609 106.894 67.5232 106.524 67.6785C106.154 67.8338 105.747 67.8751 105.354 67.7972C104.96 67.7193 104.599 67.5257 104.315 67.2409C104.031 66.9561 103.838 66.5929 103.759 66.1973C103.68 65.8017 103.72 65.3915 103.873 65.0187C104.026 64.6458 104.286 64.3271 104.619 64.1028C104.952 63.8785 105.344 63.7588 105.745 63.7588C106.281 63.7588 106.795 63.9726 107.174 64.3535C107.554 64.7343 107.768 65.2511 107.77 65.7906Z"
            fill="#D72F5D"
          />
          <path
            d="M213.824 63.2889C214.938 63.2889 215.842 62.3792 215.842 61.2571C215.842 60.135 214.938 59.2253 213.824 59.2253C212.709 59.2253 211.805 60.135 211.805 61.2571C211.805 62.3792 212.709 63.2889 213.824 63.2889Z"
            fill="#D72F5D"
          />
          <path
            d="M227.507 50.6615C228.622 50.6615 229.525 49.7518 229.525 48.6297C229.525 47.5076 228.622 46.5979 227.507 46.5979C226.392 46.5979 225.488 47.5076 225.488 48.6297C225.488 49.7518 226.392 50.6615 227.507 50.6615Z"
            fill="#D72F5D"
          />
          <path
            d="M98.8997 105.387C100.015 105.387 100.918 104.477 100.918 103.355C100.918 102.233 100.015 101.323 98.8997 101.323C97.7849 101.323 96.8811 102.233 96.8811 103.355C96.8811 104.477 97.7849 105.387 98.8997 105.387Z"
            fill="#D72F5D"
          />
          <path
            d="M77.9112 223.409C77.9125 223.813 77.7949 224.207 77.5731 224.543C77.3514 224.88 77.0356 225.142 76.6656 225.297C76.2957 225.452 75.8883 225.494 75.495 225.416C75.1017 225.338 74.7402 225.144 74.4563 224.86C74.1725 224.575 73.9789 224.212 73.9002 223.816C73.8216 223.42 73.8613 223.01 74.0144 222.637C74.1675 222.264 74.427 221.946 74.7602 221.721C75.0934 221.497 75.4852 221.377 75.886 221.377C76.422 221.377 76.9361 221.591 77.3158 221.972C77.6954 222.353 77.9095 222.87 77.9112 223.409Z"
            fill="#D72F5D"
          />
          <path
            d="M130.626 72.21C131.741 72.21 132.644 71.3004 132.644 70.1783C132.644 69.0561 131.741 68.1465 130.626 68.1465C129.511 68.1465 128.607 69.0561 128.607 70.1783C128.607 71.3004 129.511 72.21 130.626 72.21Z"
            fill="#F6605D"
          />
          <path
            d="M107.178 224.978C107.178 225.381 107.059 225.775 106.837 226.11C106.614 226.445 106.298 226.707 105.928 226.861C105.558 227.015 105.151 227.056 104.758 226.977C104.365 226.898 104.004 226.704 103.721 226.419C103.438 226.134 103.245 225.771 103.167 225.375C103.088 224.98 103.129 224.57 103.282 224.198C103.435 223.825 103.695 223.507 104.028 223.283C104.361 223.059 104.752 222.939 105.153 222.939C105.69 222.939 106.205 223.154 106.585 223.536C106.965 223.919 107.178 224.437 107.178 224.978Z"
            fill="#F6605D"
          />
          <path
            d="M110.098 192.416C111.212 192.416 112.116 191.506 112.116 190.384C112.116 189.262 111.212 188.353 110.098 188.353C108.983 188.353 108.079 189.262 108.079 190.384C108.079 191.506 108.983 192.416 110.098 192.416Z"
            fill="#F6605D"
          />
          <path
            d="M88.3266 148.749C89.4414 148.749 90.3452 147.84 90.3452 146.718C90.3452 145.595 89.4414 144.686 88.3266 144.686C87.2117 144.686 86.3079 145.595 86.3079 146.718C86.3079 147.84 87.2117 148.749 88.3266 148.749Z"
            fill="#F6605D"
          />
          <path
            d="M183.965 76.1214C185.08 76.1214 185.984 75.2118 185.984 74.0896C185.984 72.9675 185.08 72.0579 183.965 72.0579C182.85 72.0579 181.946 72.9675 181.946 74.0896C181.946 75.2118 182.85 76.1214 183.965 76.1214Z"
            fill="#F6605D"
          />
          <path
            d="M59.0926 99.755C60.2075 99.755 61.1112 98.8453 61.1112 97.7232C61.1112 96.6011 60.2075 95.6914 59.0926 95.6914C57.9777 95.6914 57.074 96.6011 57.074 97.7232C57.074 98.8453 57.9777 99.755 59.0926 99.755Z"
            fill="#F6605D"
          />
          <path
            d="M110.255 46.6971C110.257 47.1005 110.139 47.4953 109.917 47.8313C109.696 48.1674 109.38 48.4297 109.01 48.585C108.64 48.7403 108.232 48.7816 107.839 48.7037C107.446 48.6258 107.084 48.4322 106.8 48.1474C106.517 47.8626 106.323 47.4994 106.244 47.1038C106.166 46.7082 106.205 46.298 106.358 45.9252C106.512 45.5523 106.771 45.2336 107.104 45.0093C107.437 44.785 107.829 44.6653 108.23 44.6653C108.766 44.6653 109.28 44.8791 109.66 45.26C110.039 45.6408 110.254 46.1576 110.255 46.6971Z"
            fill="#F6605D"
          />
          <path
            d="M208.096 57.1736C208.315 57.3952 208.464 57.677 208.524 57.9836C208.584 58.2902 208.552 58.6077 208.433 58.8962C208.314 59.1847 208.112 59.4311 207.854 59.6045C207.596 59.7779 207.292 59.8705 206.982 59.8705C206.672 59.8705 206.368 59.7779 206.11 59.6045C205.852 59.4311 205.65 59.1847 205.531 58.8962C205.412 58.6077 205.38 58.2902 205.44 57.9836C205.5 57.677 205.649 57.3952 205.867 57.1736C206.163 56.8765 206.564 56.7097 206.982 56.7097C207.4 56.7097 207.801 56.8765 208.096 57.1736Z"
            fill="#F6605D"
          />
          <path
            d="M216.723 47.7692C217.591 47.7692 218.295 47.061 218.295 46.1875C218.295 45.3139 217.591 44.6057 216.723 44.6057C215.855 44.6057 215.152 45.3139 215.152 46.1875C215.152 47.061 215.855 47.7692 216.723 47.7692Z"
            fill="#F6605D"
          />
          <path
            d="M115.851 66.0288C115.851 66.2605 115.783 66.487 115.655 66.6796C115.527 66.8723 115.345 67.0224 115.132 67.1111C114.92 67.1997 114.686 67.2229 114.46 67.1777C114.234 67.1325 114.027 67.021 113.864 66.8572C113.701 66.6933 113.591 66.4846 113.546 66.2574C113.501 66.0301 113.524 65.7946 113.612 65.5806C113.7 65.3665 113.849 65.1836 114.041 65.0548C114.232 64.9261 114.457 64.8574 114.687 64.8574C114.996 64.8574 115.292 64.9808 115.51 65.2005C115.728 65.4202 115.851 65.7182 115.851 66.0288Z"
            fill="#F6605D"
          />
          <path
            d="M123.708 76.201C123.71 76.4342 123.642 76.6626 123.514 76.8572C123.387 77.0518 123.204 77.2038 122.991 77.294C122.777 77.3842 122.541 77.4085 122.314 77.3638C122.086 77.3191 121.877 77.2074 121.713 77.0429C121.548 76.8784 121.436 76.6685 121.391 76.4398C121.345 76.2111 121.368 75.9739 121.456 75.7583C121.545 75.5427 121.695 75.3583 121.887 75.2285C122.08 75.0988 122.306 75.0295 122.538 75.0295C122.847 75.0295 123.144 75.1527 123.363 75.3722C123.583 75.5917 123.707 75.8897 123.708 76.201Z"
            fill="#F6605D"
          />
          <path
            d="M70.2904 103.355C70.2917 103.589 70.2242 103.817 70.0963 104.012C69.9685 104.206 69.7862 104.358 69.5725 104.448C69.3587 104.539 69.1232 104.563 68.8957 104.518C68.6682 104.473 68.4591 104.362 68.2947 104.197C68.1304 104.033 68.0183 103.823 67.9726 103.594C67.9269 103.365 67.9497 103.128 68.038 102.913C68.1264 102.697 68.2764 102.513 68.4691 102.383C68.6617 102.253 68.8882 102.184 69.12 102.184C69.4293 102.184 69.726 102.307 69.9453 102.527C70.1646 102.746 70.2887 103.044 70.2904 103.355Z"
            fill="#F6605D"
          />
          <path
            d="M80.7847 143.508C80.7847 143.741 80.7159 143.969 80.587 144.163C80.4581 144.357 80.2749 144.508 80.0606 144.597C79.8464 144.686 79.6107 144.709 79.3835 144.663C79.1563 144.617 78.9477 144.504 78.7843 144.339C78.6209 144.173 78.51 143.963 78.4655 143.734C78.4211 143.505 78.4452 143.268 78.5348 143.052C78.6244 142.837 78.7755 142.654 78.9688 142.525C79.1621 142.397 79.3891 142.329 79.6208 142.33C79.7742 142.33 79.9261 142.36 80.0677 142.42C80.2094 142.479 80.338 142.566 80.4461 142.675C80.5543 142.785 80.6399 142.915 80.698 143.058C80.7561 143.2 80.7855 143.354 80.7847 143.508Z"
            fill="#F6605D"
          />
          <path
            d="M107.224 101.793C107.225 102.027 107.158 102.255 107.03 102.45C106.902 102.644 106.72 102.796 106.506 102.887C106.292 102.977 106.057 103.001 105.829 102.956C105.602 102.912 105.393 102.8 105.228 102.635C105.064 102.471 104.952 102.261 104.906 102.032C104.861 101.804 104.883 101.566 104.972 101.351C105.06 101.135 105.21 100.951 105.403 100.821C105.595 100.691 105.822 100.622 106.054 100.622C106.363 100.622 106.66 100.745 106.879 100.965C107.098 101.184 107.222 101.482 107.224 101.793Z"
            fill="#F6605D"
          />
          <path
            d="M177.666 73.3086C177.664 73.54 177.595 73.7659 177.466 73.9576C177.338 74.1494 177.156 74.2985 176.943 74.3862C176.73 74.4738 176.496 74.4961 176.271 74.4501C176.046 74.4042 175.839 74.2921 175.677 74.128C175.515 73.9639 175.404 73.7552 175.36 73.5281C175.316 73.3011 175.339 73.0659 175.427 72.8522C175.516 72.6386 175.665 72.456 175.856 72.3276C176.047 72.1992 176.272 72.1306 176.502 72.1306C176.655 72.1306 176.807 72.1611 176.949 72.2204C177.09 72.2797 177.219 72.3666 177.327 72.4761C177.435 72.5855 177.521 72.7154 177.579 72.8583C177.637 73.0012 177.667 73.1543 177.666 73.3086Z"
            fill="#F6605D"
          />
          <path
            d="M76.4317 69.0003C76.433 69.2339 76.3653 69.4625 76.2372 69.6573C76.1091 69.852 75.9264 70.004 75.7123 70.094C75.4981 70.184 75.2623 70.2079 75.0346 70.1626C74.807 70.1174 74.5978 70.005 74.4337 69.8399C74.2697 69.6747 74.1581 69.4642 74.1131 69.2351C74.0682 69.006 74.0919 68.7686 74.1813 68.553C74.2707 68.3375 74.4217 68.1536 74.6152 68.0247C74.8087 67.8957 75.0359 67.8276 75.2679 67.8289C75.576 67.8306 75.8711 67.9546 76.089 68.1739C76.3068 68.3932 76.43 68.6902 76.4317 69.0003Z"
            fill="#F6605D"
          />
          <path
            d="M105.981 50.2178C105.983 50.4511 105.915 50.6795 105.787 50.8741C105.66 51.0687 105.477 51.2207 105.263 51.3109C105.05 51.4011 104.814 51.4253 104.587 51.3806C104.359 51.3359 104.15 51.2242 103.986 51.0598C103.821 50.8953 103.709 50.6854 103.664 50.4567C103.618 50.228 103.641 49.9908 103.729 49.7751C103.817 49.5595 103.967 49.3751 104.16 49.2454C104.353 49.1156 104.579 49.0464 104.811 49.0464C105.12 49.0464 105.417 49.1696 105.636 49.3891C105.856 49.6086 105.98 49.9065 105.981 50.2178Z"
            fill="#F6605D"
          />
          <path
            d="M124.563 60.1585C124.565 60.3917 124.497 60.6202 124.369 60.8148C124.241 61.0094 124.059 61.1614 123.845 61.2516C123.632 61.3417 123.396 61.366 123.169 61.3213C122.941 61.2766 122.732 61.1649 122.568 61.0004C122.403 60.836 122.291 60.6261 122.245 60.3974C122.2 60.1687 122.223 59.9315 122.311 59.7158C122.399 59.5002 122.549 59.3158 122.742 59.1861C122.935 59.0563 123.161 58.9871 123.393 58.9871C123.702 58.9871 123.999 59.1103 124.218 59.3298C124.437 59.5493 124.562 59.8472 124.563 60.1585Z"
            fill="#F6605D"
          />
          <path
            d="M91.9825 229.279C91.9838 229.513 91.9161 229.742 91.788 229.936C91.6599 230.131 91.4771 230.283 91.263 230.373C91.0489 230.463 90.813 230.487 90.5854 230.442C90.3577 230.396 90.1486 230.284 89.9845 230.119C89.8204 229.954 89.7088 229.743 89.6638 229.514C89.6189 229.285 89.6426 229.048 89.732 228.832C89.8214 228.617 89.9724 228.433 90.1659 228.304C90.3594 228.175 90.5866 228.107 90.8186 228.108C91.1273 228.108 91.4233 228.231 91.6416 228.451C91.8599 228.671 91.9825 228.969 91.9825 229.279Z"
            fill="#F6605D"
          />
          <path
            d="M223.338 46.7962C223.339 47.0294 223.272 47.2579 223.144 47.4524C223.016 47.647 222.834 47.7991 222.62 47.8892C222.406 47.9794 222.171 48.0037 221.943 47.959C221.716 47.9143 221.507 47.8026 221.342 47.6381C221.178 47.4736 221.066 47.2638 221.02 47.0351C220.975 46.8064 220.997 46.5692 221.086 46.3535C221.174 46.1379 221.324 45.9535 221.517 45.8238C221.709 45.694 221.936 45.6248 222.168 45.6248C222.477 45.6248 222.774 45.748 222.993 45.9675C223.212 46.1869 223.336 46.4849 223.338 46.7962Z"
            fill="#F6605D"
          />
          <path
            d="M206.045 64.2285C206.045 64.4602 205.977 64.6867 205.849 64.8793C205.721 65.072 205.539 65.2221 205.327 65.3108C205.114 65.3995 204.88 65.4227 204.654 65.3775C204.428 65.3323 204.221 65.2207 204.058 65.0569C203.895 64.893 203.785 64.6843 203.74 64.4571C203.695 64.2298 203.718 63.9943 203.806 63.7803C203.894 63.5662 204.043 63.3833 204.235 63.2545C204.426 63.1258 204.651 63.0571 204.881 63.0571C205.19 63.0571 205.486 63.1805 205.704 63.4002C205.922 63.6199 206.045 63.9179 206.045 64.2285Z"
            fill="#F6605D"
          />
          <path
            opacity="0.19"
            d="M246.529 245.792C246.529 247.288 239.954 248.505 231.807 248.505C223.66 248.505 217.092 247.288 217.092 245.792C217.092 244.296 223.667 243.078 231.807 243.078C239.947 243.078 246.529 244.296 246.529 245.792Z"
            fill="black"
          />
          <path
            opacity="0.19"
            d="M179.349 261.444C179.349 262.94 172.774 264.157 164.633 264.157C156.493 264.157 149.918 262.94 149.918 261.444C149.918 259.948 156.493 258.73 164.64 258.73C172.787 258.73 179.349 259.948 179.349 261.444Z"
            fill="black"
          />
          <path
            opacity="0.19"
            d="M147.63 262.073C147.63 263.568 141.054 264.786 132.907 264.786C124.761 264.786 118.185 263.568 118.185 262.073C118.185 260.577 124.761 259.359 132.907 259.359C141.054 259.359 147.63 260.564 147.63 262.073Z"
            fill="black"
          />
          <path
            d="M79.154 193.356C80.4766 194.727 81.9973 195.89 83.6647 196.804C86.2948 198.207 85.8411 203.376 85.0652 206.506C84.2893 209.637 80.8636 209.948 76.6685 209.815C72.4734 209.683 56.9425 207.936 53.0302 210.596C49.1178 213.257 83.04 224.058 83.04 224.058L104.66 218.869C104.66 218.869 92.9951 216.367 91.2855 211.357C89.5759 206.347 89.7271 203.998 90.8186 201.966C91.9102 199.934 95.1715 198.207 98.2817 198.207C101.392 198.207 79.154 193.356 79.154 193.356Z"
            fill="#914004"
          />
          <path
            d="M226.626 223.25C226.626 223.25 229.013 221.794 229.841 221.688C230.67 221.582 232.018 223.879 232.018 223.879C232.249 223.151 232.563 222.451 232.951 221.794C233.569 220.749 235.851 223.462 235.851 223.462C235.851 223.462 236.061 221.477 236.37 221.377C236.679 221.278 239.277 223.879 239.277 223.879L236.785 227.115C236.785 227.115 228.802 227.737 228.388 227.426C227.974 227.115 226.626 223.25 226.626 223.25Z"
            fill="#343057"
          />
          <path
            d="M227.336 222.218C227.336 222.218 213.87 224.203 217.664 237.095C221.457 249.988 240.677 248.148 245.385 242.622C250.093 237.095 252.835 227.486 238.455 222.615C238.04 222.892 237.694 223.263 237.444 223.696C237.195 224.13 237.048 224.616 237.015 225.117C236.298 224.264 235.399 223.585 234.385 223.131C233.508 223.795 232.793 224.65 232.294 225.633C232.294 225.633 232.688 224.183 230.203 222.985C230.203 222.985 228.368 223.647 227.849 224.971L227.336 222.218Z"
            fill="#605A8F"
          />
          <path
            opacity="0.45"
            d="M241.552 236.923C237.751 245.884 226.33 245.031 220.596 242.218C227.303 249.266 241.44 247.228 245.385 242.595C249.988 237.155 252.736 227.77 239.112 222.827L239.066 223.019L238.455 222.588C238.455 222.588 245.497 227.638 241.552 236.923Z"
            fill="#343057"
          />
          <path
            opacity="0.16"
            d="M220.202 230.782C220.202 230.782 220.978 227.962 223.154 227.022C223.67 226.77 224.24 226.65 224.813 226.674C225.386 226.698 225.945 226.864 226.438 227.159C226.931 227.453 227.344 227.867 227.64 228.361C227.936 228.856 228.104 229.417 228.132 229.994C228.441 232.813 227.816 235.474 225.639 237.512C223.463 239.551 218.176 237.181 220.202 230.782Z"
            fill="#E0E6FF"
          />
          <path
            opacity="0.19"
            d="M130.626 56.0882C136.294 56.0882 140.89 55.1786 140.89 54.0564C140.89 52.9343 136.294 52.0247 130.626 52.0247C124.957 52.0247 120.362 52.9343 120.362 54.0564C120.362 55.1786 124.957 56.0882 130.626 56.0882Z"
            fill="black"
          />
          <path
            opacity="0.19"
            d="M207.781 49.9862C207.781 51.1112 203.178 52.0179 197.517 52.0179C191.855 52.0179 187.253 51.1112 187.253 49.9862C187.253 48.8611 191.855 47.9478 197.517 47.9478C203.178 47.9478 207.781 48.8611 207.781 49.9862Z"
            fill="black"
          />
          <path
            opacity="0.19"
            d="M158.775 77.6833C165.733 77.6833 171.373 76.5632 171.373 75.1816C171.373 73.8 165.733 72.6799 158.775 72.6799C151.817 72.6799 146.176 73.8 146.176 75.1816C146.176 76.5632 151.817 77.6833 158.775 77.6833Z"
            fill="black"
          />
          <path
            opacity="0.19"
            d="M81.6394 49.1987C88.5973 49.1987 94.2378 48.0786 94.2378 46.697C94.2378 45.3153 88.5973 44.1953 81.6394 44.1953C74.6816 44.1953 69.0411 45.3153 69.0411 46.697C69.0411 48.0786 74.6816 49.1987 81.6394 49.1987Z"
            fill="black"
          />
          <path
            opacity="0.19"
            d="M169.19 48.8876C174.514 48.8876 178.83 48.4669 178.83 47.9478C178.83 47.4288 174.514 47.0081 169.19 47.0081C163.866 47.0081 159.551 47.4288 159.551 47.9478C159.551 48.4669 163.866 48.8876 169.19 48.8876Z"
            fill="black"
          />
          <path
            d="M126.904 28.7486C126.904 28.7486 124.682 28.4905 124.162 28.4905C123.643 28.4905 123.952 31.3562 123.952 31.3562C122.979 31.1569 121.992 31.0352 120.999 30.9922C120.585 31.098 120.427 34.1226 120.427 34.1226C119.33 33.8457 118.349 33.2245 117.626 32.3489C117.614 33.6756 117.685 35.0018 117.837 36.3198C118.041 37.3125 121.986 37.6765 122.242 37.4714C122.499 37.2662 128.818 32.1768 128.818 32.0444C128.818 31.9121 126.904 28.7486 126.904 28.7486Z"
            fill="#343057"
          />
          <path
            d="M117.613 32.3489C117.613 32.3489 117.771 34.6984 115.437 38.6097C113.102 42.5211 113.569 49.2518 119.014 53.0109C124.458 56.77 135.656 55.0427 141.409 48.6296C147.163 42.2166 144.697 33.1299 139.7 29.9995C134.702 26.8691 128.811 29.2185 126.635 27.8088C126.337 28.6871 126.145 29.5981 126.062 30.5223C126.062 31.515 124.405 30.6811 123.11 30.6811C122.784 31.6667 122.542 32.6786 122.387 33.7056C122.334 34.6454 120.158 33.2887 119.533 33.1828C118.908 33.0769 119.428 33.9704 118.757 35.2212C118.087 36.472 117.613 32.3489 117.613 32.3489Z"
            fill="#605A8F"
          />
          <path
            opacity="0.45"
            d="M139.7 29.9995C136.379 27.9147 132.651 28.2589 129.883 28.3052L129.692 28.4376C143.066 29.9995 141.528 48.8878 128.66 51.9123C120.46 53.8382 116.732 50.4563 115.068 48.2657C115.917 50.1874 117.284 51.8314 119.014 53.0109C124.458 56.77 135.656 55.0427 141.409 48.6297C147.163 42.2166 144.658 33.1299 139.7 29.9995Z"
            fill="#343057"
          />
          <path
            d="M159.242 24.4732C159.242 24.4732 161.622 23.0105 162.45 22.9046C163.279 22.7988 164.633 25.0953 164.633 25.0953C164.866 24.3684 165.177 23.6693 165.561 23.0105C166.218 21.9649 168.467 24.6783 168.467 24.6783C168.467 24.6783 168.671 22.6929 168.986 22.5936C169.302 22.4943 171.886 25.0953 171.886 25.0953L169.401 28.3316C169.401 28.3316 161.418 28.9934 161.004 28.6426C160.59 28.2918 159.242 24.4732 159.242 24.4732Z"
            fill="#343057"
          />
          <path
            d="M159.952 23.4407C159.952 23.4407 146.479 25.4261 150.273 38.3117C154.067 51.1973 173.287 49.3707 177.995 43.8379C182.702 38.3051 185.425 28.7021 171.064 23.8378C170.653 24.1165 170.312 24.4864 170.065 24.9189C169.819 25.3515 169.675 25.8352 169.644 26.3328C168.923 25.4839 168.025 24.8059 167.014 24.3474C166.137 25.0109 165.422 25.8664 164.923 26.849C164.923 26.849 165.311 25.3997 162.825 24.2018C162.825 24.2018 160.997 24.8636 160.471 26.1872L159.952 23.4407Z"
            fill="#605A8F"
          />
          <path
            opacity="0.45"
            d="M174.168 38.1397C170.367 47.1007 158.939 46.2469 153.205 43.4871C159.919 50.5355 174.049 48.4971 177.995 43.8644C182.63 38.4242 185.352 29.0397 171.728 24.1025L171.682 24.2944L171.064 23.8708C171.064 23.8708 178.106 28.8543 174.168 38.1397Z"
            fill="#343057"
          />
          <path
            d="M83.8159 163.931C84.9307 163.931 85.8345 163.022 85.8345 161.9C85.8345 160.778 84.9307 159.868 83.8159 159.868C82.701 159.868 81.7972 160.778 81.7972 161.9C81.7972 163.022 82.701 163.931 83.8159 163.931Z"
            fill="#D72F5D"
          />
          <path
            d="M99.3665 166.433C100.481 166.433 101.385 165.524 101.385 164.401C101.385 163.279 100.481 162.37 99.3665 162.37C98.2517 162.37 97.3479 163.279 97.3479 164.401C97.3479 165.524 98.2517 166.433 99.3665 166.433Z"
            fill="#F6605D"
          />
          <path
            d="M61.4268 218.71C62.5417 218.71 63.4455 217.801 63.4455 216.679C63.4455 215.556 62.5417 214.647 61.4268 214.647C60.312 214.647 59.4082 215.556 59.4082 216.679C59.4082 217.801 60.312 218.71 61.4268 218.71Z"
            fill="#F6605D"
          />
          <path
            d="M104.811 163.462C104.812 163.695 104.745 163.924 104.616 164.119C104.488 164.313 104.306 164.465 104.091 164.555C103.877 164.645 103.641 164.669 103.414 164.624C103.186 164.579 102.977 164.466 102.813 164.301C102.649 164.136 102.537 163.926 102.492 163.697C102.447 163.467 102.471 163.23 102.561 163.014C102.65 162.799 102.801 162.615 102.994 162.486C103.188 162.357 103.415 162.289 103.647 162.29C103.8 162.29 103.951 162.321 104.092 162.379C104.234 162.438 104.362 162.525 104.47 162.633C104.578 162.742 104.664 162.871 104.722 163.013C104.781 163.156 104.811 163.308 104.811 163.462Z"
            fill="#F6605D"
          />
          <path
            d="M85.7622 222.39C85.7635 222.624 85.6958 222.852 85.5677 223.047C85.4396 223.242 85.2569 223.394 85.0427 223.484C84.8286 223.574 84.5927 223.598 84.3651 223.552C84.1374 223.507 83.9283 223.395 83.7642 223.23C83.6001 223.064 83.4885 222.854 83.4436 222.625C83.3986 222.396 83.4223 222.158 83.5117 221.943C83.6011 221.727 83.7522 221.543 83.9457 221.414C84.1391 221.285 84.3663 221.217 84.5984 221.219C84.9065 221.22 85.2015 221.344 85.4194 221.564C85.6373 221.783 85.7605 222.08 85.7622 222.39Z"
            fill="#F6605D"
          />
          <path
            d="M123.084 189.213C123.084 189.446 123.015 189.674 122.886 189.868C122.757 190.062 122.574 190.213 122.36 190.302C122.146 190.391 121.91 190.414 121.683 190.368C121.455 190.322 121.247 190.209 121.084 190.044C120.92 189.878 120.809 189.668 120.765 189.439C120.72 189.21 120.744 188.973 120.834 188.758C120.924 188.542 121.075 188.359 121.268 188.23C121.461 188.102 121.688 188.034 121.92 188.035C122.073 188.035 122.225 188.065 122.367 188.125C122.509 188.184 122.637 188.271 122.745 188.38C122.853 188.49 122.939 188.62 122.997 188.763C123.055 188.906 123.085 189.059 123.084 189.213Z"
            fill="#F6605D"
          />
          <path
            d="M92.0548 186.003C92.0548 186.236 91.9862 186.464 91.8576 186.658C91.729 186.851 91.5462 187.002 91.3323 187.092C91.1184 187.181 90.8831 187.204 90.6561 187.159C90.429 187.113 90.2205 187.001 90.0568 186.836C89.8931 186.671 89.7816 186.462 89.7365 186.233C89.6913 186.005 89.7145 185.768 89.8031 185.552C89.8917 185.337 90.0417 185.153 90.2342 185.024C90.4266 184.894 90.6529 184.825 90.8844 184.825C91.1948 184.825 91.4925 184.949 91.712 185.17C91.9315 185.391 92.0548 185.691 92.0548 186.003Z"
            fill="#F6605D"
          />
          <path
            opacity="0.16"
            d="M122.854 45.1351C122.854 47.2066 121.387 48.8876 119.566 48.8876C117.745 48.8876 116.278 47.2066 116.278 45.1351C116.278 43.0636 117.745 41.376 119.566 41.376C121.387 41.376 122.854 43.057 122.854 45.1351Z"
            fill="#E0E6FF"
          />
          <path
            opacity="0.16"
            d="M120.414 38.7155C120.416 38.9438 120.349 39.1674 120.224 39.3578C120.099 39.5482 119.92 39.6968 119.711 39.7848C119.502 39.8727 119.271 39.8961 119.049 39.8519C118.826 39.8077 118.622 39.6978 118.461 39.5364C118.301 39.375 118.192 39.1692 118.148 38.9452C118.104 38.7212 118.127 38.4891 118.214 38.2784C118.302 38.0677 118.45 37.8879 118.639 37.7619C118.828 37.6359 119.05 37.5693 119.277 37.5706C119.426 37.5706 119.574 37.6002 119.712 37.6577C119.85 37.7153 119.975 37.7996 120.081 37.9059C120.187 38.0122 120.271 38.1385 120.328 38.2774C120.385 38.4163 120.414 38.5652 120.414 38.7155Z"
            fill="#E0E6FF"
          />
          <path
            opacity="0.16"
            d="M151.233 32.7658C151.233 32.7658 151.89 29.1655 153.863 27.6566C155.836 26.1476 158.939 25.8829 158.893 29.5295C158.847 33.1761 157.749 35.1682 158.472 38.2986C159.196 41.429 155.467 42.3158 153.81 40.8003C152.153 39.2847 150.575 36.9419 151.233 32.7658Z"
            fill="#E0E6FF"
          />
          <path
            d="M188.37 26.9749C188.37 26.9749 190.547 25.413 191.172 25.5123C192.192 25.8297 193.07 26.4942 193.657 27.3919C193.657 27.3919 195.419 24.9961 196.044 24.9961C196.668 24.9961 198.115 27.1867 198.115 27.1867L198.431 25.5123C199.285 26.0267 199.914 26.847 200.193 27.8088C200.607 29.3773 198.01 31.4621 198.01 31.4621C198.01 31.4621 192.309 33.7586 191.895 33.5534C191.481 33.3482 188.37 26.9749 188.37 26.9749Z"
            fill="#343057"
          />
          <path
            d="M188.791 26.0615C188.791 26.0615 175.325 28.047 179.119 40.9326C182.913 53.8182 202.133 51.985 206.841 46.4588C211.548 40.9326 214.29 31.323 199.89 26.452C199.483 26.729 199.143 27.0959 198.897 27.5247C198.651 27.9536 198.505 28.4332 198.47 28.9272C197.753 28.0742 196.854 27.3954 195.84 26.9417C194.966 27.6078 194.251 28.4627 193.749 29.4434C193.749 29.4434 194.144 27.994 191.658 26.7961C191.658 26.7961 189.824 27.458 189.304 28.7816L188.791 26.0615Z"
            fill="#605A8F"
          />
          <path
            opacity="0.45"
            d="M207.307 30.4758C208.675 35.1814 206.795 43.2688 198.273 46.5382C191.303 49.1854 184.031 46.5977 181.572 45.5388C188.061 53.2291 202.797 51.204 206.841 46.4587C210.464 42.2099 212.91 35.5454 207.307 30.4758Z"
            fill="#343057"
          />
          <path
            opacity="0.16"
            d="M182.512 30.5421C183.353 29.9655 184.379 29.7291 185.385 29.8803C186.865 30.2046 188.213 31.9054 186.759 34.1953C185.306 36.4852 183.012 40.4958 181.085 39.0332C179.158 37.5706 179.31 36.0087 180.184 33.646C180.709 32.4427 181.506 31.3797 182.512 30.5421Z"
            fill="#E0E6FF"
          />
          <path
            d="M181.479 42.3158C181.479 42.3158 173.55 41.5349 170.124 47.9479C166.698 54.3609 168.414 62.1902 174.634 65.3207C180.855 68.451 189.1 68.9209 194.071 62.6734C199.042 56.4258 200.758 43.7255 181.479 42.3158Z"
            fill="#605A8F"
          />
          <path
            d="M179.665 42.3687C179.665 42.3687 181.578 43.6923 181.841 44.9233C181.841 44.9233 183.445 43.9306 184.642 44.1423C185.839 44.3541 186.45 46.2271 186.45 46.2271C186.45 46.2271 188.476 44.2416 189.409 44.9034C190.343 45.5653 191.014 46.7301 190.862 46.6771C190.711 46.6242 191.066 44.7975 191.842 45.0093C191.842 45.0093 191.185 42.2959 189.981 42.4547C189.218 42.5727 188.501 42.895 187.904 43.3879C187.746 42.6065 187.337 41.8993 186.74 41.3759C185.859 40.7141 183.991 42.5738 183.991 42.5738C183.603 41.8603 183.031 41.2657 182.334 40.8531C181.322 40.3303 179.665 42.3687 179.665 42.3687Z"
            fill="#343057"
          />
          <path
            opacity="0.45"
            d="M195.801 48.4707C195.945 49.4436 196.879 57.3391 188.712 61.8262C179.954 66.631 170.959 59.4304 169.9 57.6038C169.771 57.3788 169.574 57.201 169.337 57.0963C169.101 56.9916 168.837 56.9654 168.585 57.0214C169.407 60.5754 171.478 63.7256 174.654 65.3206C180.875 68.451 189.12 68.9209 194.091 62.6733C197.017 58.9605 198.819 53.0108 195.801 48.4707Z"
            fill="#343057"
          />
          <path
            d="M154.764 49.7216C154.764 49.7216 144.565 49.1988 141.166 57.6171C137.767 66.0354 141.429 76.0488 155.941 76.1811C170.453 76.3135 172.932 67.2267 172.682 61.5682C172.432 55.9096 167.323 49.1988 154.764 49.7216Z"
            fill="#605A8F"
          />
          <path
            d="M152.292 54.7381C152.292 54.7381 152.193 51.9187 153.541 52.7526C154.229 53.1437 154.856 53.6342 155.402 54.2086C155.402 54.2086 155.921 52.9578 156.717 52.7526C157.512 52.5475 157.808 55.2543 157.808 55.2543C157.808 55.2543 159.879 54.1093 160.609 54.7315C161.339 55.3536 159.676 56.7169 159.676 56.7169C159.676 56.7169 160.813 57.2397 159.879 57.9677C158.946 58.6957 159.676 60.4694 157.598 60.4694C157.338 60.4909 157.077 60.4532 156.834 60.3593C156.591 60.2654 156.372 60.1177 156.193 59.927C156.014 59.7363 155.881 59.5076 155.802 59.2578C155.723 59.008 155.701 58.7435 155.737 58.484C155.737 58.484 154.179 60.2576 153.14 59.523C152.101 58.7884 152.416 57.3324 152.416 57.3324C152.019 57.3233 151.63 57.2136 151.285 57.0135C150.94 56.8135 150.651 56.5294 150.444 56.1875C149.806 55.0425 152.292 54.7381 152.292 54.7381Z"
            fill="#343057"
          />
          <path
            opacity="0.45"
            d="M172.682 61.568C172.64 60.7263 172.497 59.8928 172.254 59.0862C172.412 63.2292 170.61 70.9989 159.551 73.4079C148.78 75.7905 142.317 70.0194 141.258 68.9804C143.323 73.0836 148.004 76.1081 155.941 76.1809C170.459 76.3067 172.931 67.2265 172.682 61.568Z"
            fill="#343057"
          />
          <path
            opacity="0.16"
            d="M142.448 60.1055C142.87 58.8751 143.739 57.8512 144.881 57.2398C146.643 56.4523 149.287 57.1869 149.484 60.6283C149.681 64.0698 150.312 69.8938 147.255 69.8607C144.197 69.8276 143.185 68.0937 142.33 65.0096C141.989 63.388 142.029 61.7086 142.448 60.1055Z"
            fill="#E0E6FF"
          />
          <path
            opacity="0.19"
            d="M194.696 195.706C200.978 195.706 206.071 194.692 206.071 193.442C206.071 192.192 200.978 191.179 194.696 191.179C188.413 191.179 183.321 192.192 183.321 193.442C183.321 194.692 188.413 195.706 194.696 195.706Z"
            fill="black"
          />
          <path
            d="M195.011 170.166C195.011 170.166 185.806 169.69 182.729 177.3C179.651 184.911 182.965 193.939 196.07 194.058C209.175 194.177 211.424 185.977 211.193 180.868C210.963 175.758 206.347 169.69 195.011 170.166Z"
            fill="#605A8F"
          />
          <path
            d="M192.776 174.693C192.776 174.693 192.684 172.145 193.9 172.899C194.523 173.255 195.09 173.701 195.584 174.223C195.584 174.223 196.057 173.091 196.8 172.899C197.543 172.707 197.74 175.163 197.74 175.163C197.74 175.163 199.614 174.124 200.265 174.693C200.916 175.262 199.424 176.48 199.424 176.48C199.424 176.48 200.456 176.95 199.614 177.611C198.773 178.273 199.424 179.875 197.55 179.875C197.315 179.893 197.08 179.858 196.861 179.772C196.642 179.687 196.445 179.554 196.283 179.382C196.122 179.21 196.001 179.004 195.929 178.779C195.857 178.554 195.835 178.316 195.866 178.081C195.866 178.081 194.466 179.683 193.525 179.028C193.249 178.787 193.042 178.475 192.926 178.126C192.811 177.776 192.79 177.402 192.868 177.042C192.509 177.033 192.158 176.933 191.848 176.751C191.538 176.57 191.278 176.312 191.093 176.003C190.527 174.971 192.776 174.693 192.776 174.693Z"
            fill="#343057"
          />
          <path
            opacity="0.45"
            d="M211.187 180.867C211.152 180.104 211.024 179.349 210.805 178.617C210.944 182.363 209.319 189.352 199.331 191.562C189.607 193.707 183.768 188.498 182.808 187.558C184.675 191.265 188.91 193.991 196.07 194.057C209.181 194.177 211.424 185.977 211.187 180.867Z"
            fill="#343057"
          />
          <path
            opacity="0.16"
            d="M183.886 179.544C184.264 178.429 185.049 177.502 186.082 176.949C187.673 176.248 190.06 176.903 190.251 180.014C190.442 183.124 191 188.405 188.239 188.353C185.477 188.3 184.563 186.758 183.787 183.971C183.479 182.508 183.513 180.992 183.886 179.544Z"
            fill="#E0E6FF"
          />
          <path
            opacity="0.16"
            d="M176.581 52.2232C175.772 54.1292 173.767 55.0955 172.11 54.3807C170.453 53.666 169.769 51.5415 170.578 49.6355C171.386 47.7295 173.392 46.7566 175.049 47.4713C176.706 48.1861 177.39 50.3105 176.581 52.2232Z"
            fill="#E0E6FF"
          />
          <path
            opacity="0.16"
            d="M177.784 45.9824C177.695 46.1921 177.546 46.3705 177.357 46.4951C177.167 46.6196 176.945 46.6846 176.718 46.682C176.492 46.6793 176.271 46.609 176.084 46.48C175.898 46.351 175.753 46.1691 175.669 45.9573C175.585 45.7456 175.566 45.5135 175.613 45.2905C175.66 45.0676 175.772 44.8637 175.934 44.7048C176.097 44.5458 176.302 44.439 176.525 44.3978C176.748 44.3567 176.978 44.383 177.186 44.4734C177.463 44.5943 177.682 44.8208 177.794 45.1036C177.907 45.3864 177.903 45.7023 177.784 45.9824Z"
            fill="#E0E6FF"
          />
          <path
            d="M151.93 242.35C151.93 242.35 154.422 238.28 155.665 238.28C156.48 238.306 157.278 238.518 157.999 238.902C157.999 238.902 158.926 236.738 160.175 236.738C161.425 236.738 163.752 238.148 163.752 238.148L163.285 244.104L151.93 242.35Z"
            fill="#343057"
          />
          <path
            d="M152.778 239.518C152.778 239.518 140.239 244.852 147.15 256.368C154.06 267.883 172.195 261.206 175.358 254.66C178.521 248.115 178.77 238.115 163.627 237.069C163.297 237.444 163.056 237.89 162.924 238.373C162.791 238.856 162.771 239.363 162.865 239.855C161.961 239.219 160.928 238.794 159.84 238.611C159.159 239.476 158.682 240.485 158.446 241.563C158.446 241.563 158.446 240.061 155.757 239.577C155.757 239.577 154.152 240.676 153.975 242.086L152.778 239.518Z"
            fill="#605A8F"
          />
          <path
            opacity="0.16"
            d="M149.661 249.194C149.661 249.194 150.279 246.957 152.009 246.209C152.418 246.011 152.87 245.918 153.324 245.938C153.778 245.958 154.22 246.091 154.611 246.325C155.002 246.559 155.329 246.886 155.563 247.278C155.797 247.67 155.932 248.115 155.954 248.571C156.129 249.651 156.041 250.757 155.698 251.795C155.354 252.832 154.765 253.77 153.981 254.528C152.259 256.163 148.057 254.296 149.661 249.194Z"
            fill="#E0E6FF"
          />
          <path
            d="M137.425 238.598C137.425 238.598 135.847 236.996 135.452 236.672C135.058 236.348 133.512 238.783 133.512 238.783C132.874 238.015 132.177 237.298 131.428 236.639C131.04 236.46 129.041 238.724 129.041 238.724C128.353 237.82 127.973 236.718 127.956 235.58C127.124 236.607 126.358 237.686 125.661 238.81C125.208 239.716 128.055 242.476 128.39 242.476C128.726 242.476 136.8 242.476 136.899 242.377C136.997 242.278 137.425 238.598 137.425 238.598Z"
            fill="#343057"
          />
          <path
            d="M127.956 235.58C127.956 235.58 126.641 237.513 122.374 239.101C118.106 240.689 114.312 246.242 116.232 252.602C118.152 258.962 127.969 264.647 136.445 263.251C144.921 261.854 148.603 253.198 146.663 247.625C144.723 242.053 138.641 240.173 137.819 237.698C137.043 238.198 136.327 238.789 135.689 239.458C135.071 240.233 134.295 238.538 133.282 237.724C132.415 238.287 131.599 238.925 130.843 239.63C130.185 240.332 129.357 237.903 128.936 237.433C128.515 236.963 128.37 237.976 127.069 238.532C125.767 239.088 127.956 235.58 127.956 235.58Z"
            fill="#605A8F"
          />
          <path
            opacity="0.16"
            d="M119.783 243.303C119.783 243.303 121.499 241.04 123.814 240.98C124.371 240.941 124.93 241.041 125.44 241.272C125.95 241.503 126.395 241.858 126.735 242.304C127.075 242.751 127.3 243.275 127.389 243.831C127.478 244.387 127.429 244.956 127.246 245.487C126.516 248.135 124.991 250.339 122.288 251.384C119.586 252.43 115.641 248.38 119.783 243.303Z"
            fill="#E0E6FF"
          />
          <path
            opacity="0.45"
            d="M139.022 242.82C141.357 246.573 142.757 253.621 136.392 258.155C130.843 262.125 121.269 258.115 118.909 257.016C122.854 261.298 130.034 264.296 136.458 263.251C144.927 261.854 148.616 253.198 146.676 247.625C144.736 242.053 138.654 240.173 137.832 237.698C137.819 237.704 136.688 239.061 139.022 242.82Z"
            fill="#343057"
          />
          <path
            opacity="0.45"
            d="M149.872 259.571C157.946 266.374 172.55 260.471 175.358 254.66C178.336 248.512 178.731 239.333 166.231 237.36C169.335 238.022 174.023 240.305 173.392 247.83C172.471 258.764 158.61 262.873 149.872 259.571Z"
            fill="#343057"
          />
          <path
            d="M79.0751 24.2151C79.0751 24.2151 66.3584 23.8379 64.1162 34.7513C61.874 45.6647 73.8345 52.0909 87.6756 45.2874C101.517 38.4839 97.0192 21.2039 79.0751 24.2151Z"
            fill="#605A8F"
          />
          <path
            d="M78.1348 25.2806C78.1348 25.2806 78.8844 21.9715 78.0164 20.6479C77.1485 19.3242 78.5753 18.9602 79.2 19.0198C79.8246 19.0793 80.1337 22.6598 79.509 23.9768C78.8844 25.2938 78.7594 26.2204 78.1348 25.2806Z"
            fill="#343057"
          />
          <path
            opacity="0.45"
            d="M91.0224 25.8762C93.0608 28.9801 93.7578 37.8684 85.0586 43.785C76.6685 49.4899 67.2263 44.4998 66.2597 43.9571C70.2049 48.4906 78.5293 49.7679 87.6756 45.2807C97.5649 40.4428 98.0844 30.2508 91.0224 25.8762Z"
            fill="#343057"
          />
          <path
            opacity="0.16"
            d="M66.7923 32.6467C66.7923 32.6467 67.6603 30.3833 69.5342 29.7877C69.9802 29.6217 70.4601 29.5697 70.931 29.6363C71.4018 29.703 71.8489 29.8862 72.2321 30.1696C72.6153 30.4529 72.9226 30.8276 73.1265 31.2599C73.3305 31.6922 73.4246 32.1688 73.4005 32.6467C73.4005 34.9962 72.697 37.1405 70.7704 38.6428C68.8438 40.1451 64.6027 37.7758 66.7923 32.6467Z"
            fill="#E0E6FF"
          />
          <path
            opacity="0.18"
            d="M167.329 192.264C167.329 193.297 166.073 194.144 164.528 194.144C162.983 194.144 161.753 193.297 161.753 192.264C161.753 191.232 163.009 190.385 164.555 190.385C166.1 190.385 167.329 191.225 167.329 192.264Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M57.2252 178.174C58.7722 178.174 60.0263 177.332 60.0263 176.294C60.0263 175.256 58.7722 174.415 57.2252 174.415C55.6782 174.415 54.4241 175.256 54.4241 176.294C54.4241 177.332 55.6782 178.174 57.2252 178.174Z"
            fill="#CC562A"
          />
          <path
            opacity="0.34"
            d="M139.956 233.27C139.956 234.309 138.7 235.15 137.155 235.15C135.61 235.15 134.361 234.309 134.361 233.27C134.361 232.231 135.61 231.391 137.155 231.391C138.7 231.391 139.956 232.231 139.956 233.27Z"
            fill="#CC562A"
          />
          <path
            opacity="0.28"
            d="M218.019 186.943C219.566 186.943 220.82 186.101 220.82 185.063C220.82 184.025 219.566 183.184 218.019 183.184C216.472 183.184 215.218 184.025 215.218 185.063C215.218 186.101 216.472 186.943 218.019 186.943Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M156.75 154.673C156.75 155.705 155.5 156.552 153.955 156.552C152.41 156.552 151.154 155.705 151.154 154.673C151.154 153.64 152.403 152.793 153.955 152.793C155.507 152.793 156.75 153.66 156.75 154.673Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M140.89 104.295C140.89 105.334 139.634 106.175 138.089 106.175C136.544 106.175 135.294 105.334 135.294 104.295C135.294 103.256 136.544 102.422 138.089 102.422C139.634 102.422 140.89 103.263 140.89 104.295Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M213.981 98.663C213.981 99.7021 212.725 100.543 211.18 100.543C209.635 100.543 208.379 99.7021 208.379 98.663C208.379 97.624 209.635 96.7834 211.18 96.7834C212.725 96.7834 213.981 97.624 213.981 98.663Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M60.0263 62.349C61.5733 62.349 62.8274 61.5075 62.8274 60.4694C62.8274 59.4314 61.5733 58.5898 60.0263 58.5898C58.4793 58.5898 57.2252 59.4314 57.2252 60.4694C57.2252 61.5075 58.4793 62.349 60.0263 62.349Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M210.871 74.2485C210.871 75.2809 209.615 76.1214 208.07 76.1214C206.525 76.1214 205.269 75.2809 205.269 74.2485C205.269 73.216 206.525 72.3689 208.07 72.3689C209.615 72.3689 210.871 73.2094 210.871 74.2485Z"
            fill="#CC562A"
          />
          <path
            opacity="0.41"
            d="M238.862 69.8608C238.862 70.8998 237.607 71.7404 236.061 71.7404C234.516 71.7404 233.26 70.8998 233.26 69.8608C233.26 68.8217 234.516 67.9746 236.055 67.9746C237.593 67.9746 238.862 68.8284 238.862 69.8608Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M89.4246 55.6184C89.4246 56.2272 88.6948 56.717 87.7874 56.717C86.88 56.717 86.137 56.2272 86.137 55.6184C86.137 55.0095 86.8668 54.5264 87.7676 54.5264C88.6685 54.5264 89.4246 55.0161 89.4246 55.6184Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M108.309 40.2773C108.309 40.8862 107.579 41.3759 106.678 41.3759C105.778 41.3759 105.048 40.8862 105.048 40.2773C105.048 39.6684 105.778 39.1853 106.678 39.1853C107.579 39.1853 108.309 39.675 108.309 40.2773Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M63.5244 50.1384C63.5244 50.7473 62.7946 51.237 61.8937 51.237C60.9929 51.237 60.2565 50.7473 60.2565 50.1384C60.2565 49.5295 60.9929 49.0464 61.8937 49.0464C62.7946 49.0464 63.5244 49.5361 63.5244 50.1384Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M75.6559 52.9577C75.6559 53.5666 74.926 54.0563 74.0186 54.0563C73.1112 54.0563 72.3879 53.5666 72.3879 52.9577C72.3879 52.3489 73.1178 51.8657 74.0186 51.8657C74.9194 51.8657 75.6559 52.3555 75.6559 52.9577Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M113.911 36.6838C113.911 37.2861 113.181 37.7758 112.274 37.7758C111.367 37.7758 110.643 37.2861 110.643 36.6838C110.643 36.0816 111.373 35.5852 112.274 35.5852C113.175 35.5852 113.911 36.075 113.911 36.6838Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M127.516 118.385C129.063 118.385 130.317 117.544 130.317 116.506C130.317 115.467 129.063 114.626 127.516 114.626C125.969 114.626 124.715 115.467 124.715 116.506C124.715 117.544 125.969 118.385 127.516 118.385Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M226.731 123.395C226.731 124.428 225.475 125.268 223.93 125.268C222.385 125.268 221.129 124.428 221.129 123.395C221.129 122.363 222.385 121.516 223.93 121.516C225.475 121.516 226.731 122.356 226.731 123.395Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M152.706 234.839C152.706 235.871 151.456 236.712 149.911 236.712C148.366 236.712 147.11 235.871 147.11 234.839C147.11 233.806 148.366 232.959 149.911 232.959C151.456 232.959 152.706 233.8 152.706 234.839Z"
            fill="#CC562A"
          />
          <path
            opacity="0.16"
            d="M168.572 204.786C168.572 205.818 167.316 206.665 165.771 206.665C164.226 206.665 162.97 205.818 162.97 204.786C162.97 203.753 164.226 202.906 165.771 202.906C167.316 202.906 168.572 203.747 168.572 204.786Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M213.666 145.93C215.213 145.93 216.467 145.089 216.467 144.05C216.467 143.012 215.213 142.171 213.666 142.171C212.119 142.171 210.865 143.012 210.865 144.05C210.865 145.089 212.119 145.93 213.666 145.93Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M211.18 124.017C211.18 124.54 210.556 124.957 209.78 124.957C209.004 124.957 208.379 124.54 208.379 124.017C208.379 123.494 209.004 123.077 209.78 123.077C210.556 123.077 211.18 123.501 211.18 124.017Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M158.617 232.959C159.39 232.959 160.018 232.538 160.018 232.019C160.018 231.5 159.39 231.08 158.617 231.08C157.843 231.08 157.216 231.5 157.216 232.019C157.216 232.538 157.843 232.959 158.617 232.959Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M137.938 113.686C137.938 114.209 137.28 114.626 136.537 114.626C135.794 114.626 135.136 114.209 135.136 113.686C135.136 113.163 135.761 112.746 136.537 112.746C137.313 112.746 137.938 113.17 137.938 113.686Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M204.96 149.378C205.734 149.378 206.361 148.957 206.361 148.438C206.361 147.919 205.734 147.499 204.96 147.499C204.187 147.499 203.559 147.919 203.559 148.438C203.559 148.957 204.187 149.378 204.96 149.378Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M198.74 148.438C199.513 148.438 200.14 148.018 200.14 147.499C200.14 146.98 199.513 146.559 198.74 146.559C197.966 146.559 197.339 146.98 197.339 147.499C197.339 148.018 197.966 148.438 198.74 148.438Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M158.617 199.147C159.39 199.147 160.018 198.726 160.018 198.207C160.018 197.688 159.39 197.267 158.617 197.267C157.843 197.267 157.216 197.688 157.216 198.207C157.216 198.726 157.843 199.147 158.617 199.147Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M103.568 205.097C104.342 205.097 104.969 204.676 104.969 204.157C104.969 203.638 104.342 203.217 103.568 203.217C102.795 203.217 102.168 203.638 102.168 204.157C102.168 204.676 102.795 205.097 103.568 205.097Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M68.732 190.384C69.5055 190.384 70.1326 189.964 70.1326 189.445C70.1326 188.926 69.5055 188.505 68.732 188.505C67.9585 188.505 67.3315 188.926 67.3315 189.445C67.3315 189.964 67.9585 190.384 68.732 190.384Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M119.428 235.461C120.201 235.461 120.828 235.04 120.828 234.521C120.828 234.002 120.201 233.581 119.428 233.581C118.654 233.581 118.027 234.002 118.027 234.521C118.027 235.04 118.654 235.461 119.428 235.461Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M137.004 186.003C137.004 186.519 136.346 186.943 135.603 186.943C134.86 186.943 134.203 186.519 134.203 186.003C134.203 185.487 134.86 185.063 135.603 185.063C136.346 185.063 137.004 185.48 137.004 186.003Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M70.5995 180.682C71.373 180.682 72 180.261 72 179.742C72 179.223 71.373 178.802 70.5995 178.802C69.826 178.802 69.1989 179.223 69.1989 179.742C69.1989 180.261 69.826 180.682 70.5995 180.682Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M169.506 151.569C170.279 151.569 170.906 151.148 170.906 150.629C170.906 150.11 170.279 149.689 169.506 149.689C168.732 149.689 168.105 150.11 168.105 150.629C168.105 151.148 168.732 151.569 169.506 151.569Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M166.547 232.641C166.547 233.164 165.922 233.581 165.146 233.581C164.37 233.581 163.752 233.164 163.752 232.641C163.752 232.119 164.41 231.708 165.146 231.708C165.883 231.708 166.547 232.105 166.547 232.641Z"
            fill="#CC562A"
          />
          <path
            opacity="0.22"
            d="M64.537 180.993C64.537 181.509 63.8795 181.933 63.1364 181.933C62.3934 181.933 61.7359 181.509 61.7359 180.993C61.7359 180.477 62.3934 180.053 63.1364 180.053C63.8795 180.053 64.537 180.484 64.537 180.993Z"
            fill="#CC562A"
          />
          <path
            opacity="0.22"
            d="M57.5408 205.408C58.3143 205.408 58.9414 204.987 58.9414 204.468C58.9414 203.949 58.3143 203.528 57.5408 203.528C56.7673 203.528 56.1403 203.949 56.1403 204.468C56.1403 204.987 56.7673 205.408 57.5408 205.408Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M153.021 191.953C153.795 191.953 154.422 191.532 154.422 191.013C154.422 190.494 153.795 190.073 153.021 190.073C152.248 190.073 151.621 190.494 151.621 191.013C151.621 191.532 152.248 191.953 153.021 191.953Z"
            fill="#CC562A"
          />
          <path
            opacity="0.18"
            d="M181.479 202.595C182.253 202.595 182.88 202.174 182.88 201.655C182.88 201.136 182.253 200.715 181.479 200.715C180.706 200.715 180.079 201.136 180.079 201.655C180.079 202.174 180.706 202.595 181.479 202.595Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M163.595 105.235C164.368 105.235 164.995 104.814 164.995 104.295C164.995 103.776 164.368 103.355 163.595 103.355C162.821 103.355 162.194 103.776 162.194 104.295C162.194 104.814 162.821 105.235 163.595 105.235Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M224.219 95.2215C224.219 95.7377 223.595 96.1613 222.825 96.1613C222.056 96.1613 221.425 95.7377 221.425 95.2215C221.425 94.7053 222.082 94.2817 222.825 94.2817C223.568 94.2817 224.219 94.6987 224.219 95.2215Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M204.184 98.663C204.958 98.663 205.585 98.2423 205.585 97.7232C205.585 97.2042 204.958 96.7834 204.184 96.7834C203.411 96.7834 202.784 97.2042 202.784 97.7232C202.784 98.2423 203.411 98.663 204.184 98.663Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M67.4893 57.9677C68.2628 57.9677 68.8898 57.5469 68.8898 57.0279C68.8898 56.5089 68.2628 56.0881 67.4893 56.0881C66.7158 56.0881 66.0887 56.5089 66.0887 57.0279C66.0887 57.5469 66.7158 57.9677 67.4893 57.9677Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M104.66 36.3661C104.66 36.8889 104.002 37.3058 103.259 37.3058C102.516 37.3058 101.859 36.8889 101.859 36.3661C101.859 35.8432 102.516 35.4263 103.259 35.4263C104.002 35.4263 104.66 35.8498 104.66 36.3661Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M216.158 71.4291C216.931 71.4291 217.558 71.0084 217.558 70.4893C217.558 69.9703 216.931 69.5496 216.158 69.5496C215.384 69.5496 214.757 69.9703 214.757 70.4893C214.757 71.0084 215.384 71.4291 216.158 71.4291Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M234.036 114.626C234.81 114.626 235.437 114.205 235.437 113.686C235.437 113.167 234.81 112.746 234.036 112.746C233.263 112.746 232.636 113.167 232.636 113.686C232.636 114.205 233.263 114.626 234.036 114.626Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M173.083 108.683C173.083 109.199 172.425 109.616 171.682 109.616C170.939 109.616 170.282 109.199 170.282 108.683C170.282 108.167 170.906 107.743 171.682 107.743C172.458 107.743 173.083 108.16 173.083 108.683Z"
            fill="#CC562A"
          />
          <path
            opacity="0.38"
            d="M123.939 110.556C124.712 110.556 125.339 110.135 125.339 109.616C125.339 109.097 124.712 108.676 123.939 108.676C123.165 108.676 122.538 109.097 122.538 109.616C122.538 110.135 123.165 110.556 123.939 110.556Z"
            fill="#CC562A"
          />
          <path
            opacity="0.21"
            d="M79.7786 59.5298C79.7786 60.0527 79.1211 60.4696 78.3781 60.4696C77.6351 60.4696 76.9775 60.0527 76.9775 59.5298C76.9775 59.007 77.6351 58.5967 78.3781 58.5967C79.1211 58.5967 79.7786 59.0136 79.7786 59.5298Z"
            fill="#CC562A"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_8_5133"
            x1="287.836"
            y1="208.902"
            x2="10.6965"
            y2="230.227"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D72F5D" />
            <stop offset="0.33" stopColor="#D83360" />
            <stop offset="0.64" stopColor="#DA3E68" />
            <stop offset="0.93" stopColor="#DD5077" />
            <stop offset="1" stopColor="#DE567B" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_8_5133"
            x1="4184.03"
            y1="28434.3"
            x2="111672"
            y2="28434.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D72F5D" />
            <stop offset="0.33" stopColor="#D83360" />
            <stop offset="0.64" stopColor="#DA3E68" />
            <stop offset="0.93" stopColor="#DD5077" />
            <stop offset="1" stopColor="#DE567B" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_8_5133"
            x1="44.2915"
            y1="218.988"
            x2="252.605"
            y2="218.988"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F0A82C" />
            <stop offset="0.31" stopColor="#EFA42C" />
            <stop offset="0.59" stopColor="#EB992C" />
            <stop offset="0.86" stopColor="#E6872C" />
            <stop offset="1" stopColor="#E27A2C" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_8_5133"
            x1="13578.1"
            y1="11920.4"
            x2="51027.1"
            y2="37142.3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F0A82C" />
            <stop offset="0.31" stopColor="#EFA42C" />
            <stop offset="0.59" stopColor="#EB992C" />
            <stop offset="0.86" stopColor="#E6872C" />
            <stop offset="1" stopColor="#E27A2C" />
          </linearGradient>
          <radialGradient
            id="paint4_radial_8_5133"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(46732.4 9033.85) scale(22410.1 5650.2)"
          >
            <stop stopColor="#F0A82C" />
            <stop offset="0.31" stopColor="#EFA42C" />
            <stop offset="0.59" stopColor="#EB992C" />
            <stop offset="0.86" stopColor="#E6872C" />
            <stop offset="1" stopColor="#E27A2C" />
          </radialGradient>
          <radialGradient
            id="paint5_radial_8_5133"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(153.508 112.164) scale(75.794 76.2878)"
          >
            <stop stopColor="#E27A2C" />
            <stop offset="0.28" stopColor="#E4802C" />
            <stop offset="0.66" stopColor="#E9932C" />
            <stop offset="1" stopColor="#F0A82C" />
          </radialGradient>
          <radialGradient
            id="paint6_radial_8_5133"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(68066.4 510.733) scale(40999.1 8833.58)"
          >
            <stop stopColor="#F0A82C" />
            <stop offset="0.31" stopColor="#EFA42C" />
            <stop offset="0.59" stopColor="#EB992C" />
            <stop offset="0.86" stopColor="#E6872C" />
            <stop offset="1" stopColor="#E27A2C" />
          </radialGradient>
          <linearGradient
            id="paint7_linear_8_5133"
            x1="13671.6"
            y1="3021.69"
            x2="78909.7"
            y2="3021.69"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F0A82C" />
            <stop offset="0.31" stopColor="#EFA42C" />
            <stop offset="0.59" stopColor="#EB992C" />
            <stop offset="0.86" stopColor="#E6872C" />
            <stop offset="1" stopColor="#E27A2C" />
          </linearGradient>
          <clipPath id="clip0_8_5133">
            <rect
              width="288"
              height="263"
              fill="white"
              transform="translate(0 19)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
