export default function () {
  return (
    <>
      <svg
        width="118"
        height="74"
        viewBox="0 0 118 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_8_7061)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.843 63.2942C108.843 68.4011 85.7092 74 61.1716 74C36.634 74 16.5714 65.1252 16.5714 60.0183C16.5714 54.9115 39.8868 54.052 64.4182 54.052C88.9496 54.052 108.843 58.1873 108.843 63.2942Z"
            fill="#E2CAB3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.641 68.507C112.71 71.2349 106.799 73.6015 99.435 73.7883C92.0706 73.9751 86.0412 71.9199 85.9722 69.1859C85.9033 66.4518 91.8136 64.0915 99.178 63.9046C106.542 63.7178 112.572 65.773 112.641 68.507Z"
            fill="#E2CAB3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.41487 49.0946C1.49136 58.3368 6.26726 63.1759 12.6289 63.9731C15.1359 64.2845 18.8965 62.5594 21.6793 61.4819C23.9435 60.4826 26.0931 59.2445 28.091 57.7888C29.0374 56.8235 30.1531 55.5094 30.3787 54.052C30.409 53.4888 30.2879 52.9278 30.0277 52.4265C30.0985 52.2166 30.2021 52.019 30.3348 51.8411C30.6733 51.2183 29.7582 50.7388 30.2157 50.0413C30.9428 48.9265 29.9838 47.7245 29.5138 46.7342C29.3571 46.4166 25.3145 42.848 25.1265 42.5055C21.7545 38.9618 20.8457 37.2927 16.2077 36.5641C10.3851 35.6423 4.73797 37.7536 5.41487 49.0946Z"
            fill="#5F825C"
          />
          <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.52089 45.6818C8.18526 45.7627 8.51117 47.5501 8.2354 49.6925C7.95962 51.8349 7.20751 53.4791 6.53688 53.3981C5.86625 53.3172 5.55287 51.5298 5.82237 49.3874C6.09188 47.245 6.81892 45.5946 7.52089 45.6818Z"
              fill="#5F825C"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.14763 55.7026C6.72489 56.3254 6.85651 58.1501 7.43313 59.3334C7.44729 59.3521 7.4579 59.3732 7.46447 59.3957C7.59988 59.6683 7.76826 59.9235 7.96587 60.1555C8.66433 61.0391 9.62819 61.6783 10.7173 61.9803C12.0837 62.3228 13.6506 62.0924 14.4403 61.015C14.4596 60.9818 14.491 60.9573 14.528 60.9465V60.8094C14.3526 60.1119 13.2745 59.9749 12.6979 59.8877C11.4444 59.6946 11.0746 58.5985 10.6923 57.5834C10.31 56.5682 9.40115 55.1296 8.14763 55.7026Z"
            fill="#8DB54F"
          />
          <g style={{ mixBlendMode: "multiply" }} opacity="0.4">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M21.0211 41.9761C19.8616 42.5989 20.9397 44.0064 21.4975 44.6728C22.751 46.2173 24.4683 47.3259 25.515 49.0822C26.5617 50.8384 25.1954 53.56 26.8688 55.0423C26.9495 55.1139 27.0437 55.1688 27.1459 55.2038C27.2481 55.2389 27.3564 55.2533 27.4643 55.2464C27.5722 55.2394 27.6777 55.2112 27.7745 55.1633C27.8714 55.1154 27.9576 55.0489 28.0283 54.9675C28.1016 54.8498 28.1566 54.7217 28.1913 54.5876C28.395 54.3291 28.5538 54.0386 28.6613 53.7282C28.9433 52.7538 28.906 51.7156 28.5548 50.7637C28.2915 49.9105 28.8807 49.0822 28.6237 48.2289C28.506 47.911 28.3174 47.6236 28.0722 47.3882C27.8151 47.0918 27.5201 46.8301 27.1947 46.6097C26.5881 46.1084 26.028 45.554 25.5213 44.9531C24.9143 44.2383 24.2475 43.5757 23.5282 42.9726C22.7698 42.2564 21.7858 41.5589 21.0211 41.9761Z"
              fill="#5F825C"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.4805 49.6678C5.85374 49.5619 5.45889 49.3128 3.43445 48.8146C1.41002 48.3163 0.382134 48.2042 0.0687547 48.0174C-0.244625 47.8306 0.632838 46.8528 0.632838 46.8528C0.632838 46.8528 2.1496 47.432 3.39685 47.8928C4.6441 48.3537 6.9255 48.9641 6.9255 48.9641C6.9255 48.9641 7.28276 49.1633 7.1386 49.4872C6.99445 49.811 6.4805 49.6678 6.4805 49.6678Z"
            fill="#300D33"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.817 31.6627C111.118 32.0551 109.683 33.0765 107.915 33.1637C106.035 33.2571 104.155 32.4786 102.318 32.1236C98.3259 31.3514 94.2395 30.9216 90.2031 30.4794C82.0552 29.5826 73.8008 29.1965 65.6216 28.2623C57.4424 27.3281 49.3259 26.2631 41.1404 26.3005C37.197 26.3104 33.2615 26.652 29.3761 27.3219C26.1734 27.8762 22.9142 28.5239 20.2317 30.4358C18.1671 31.9449 16.6112 34.0403 15.7692 36.4458C14.6786 39.3729 20.4385 57.2968 21.3097 58.4739C28.881 68.4385 41.5917 70.6183 62.5066 70.201C84.8569 69.7526 93.4936 67.2801 99.8239 61.7249C103.227 58.7417 106.091 51.0689 106.605 49.568C107.477 46.9211 108.442 44.3116 109.344 41.6772C109.915 40.0081 110.51 38.3577 111.225 36.7447C111.981 35.2293 112.61 33.6546 113.105 32.0364C113.119 31.9924 113.122 31.9455 113.114 31.8999C113.106 31.8543 113.088 31.8113 113.059 31.7746C113.031 31.7379 112.994 31.7085 112.952 31.689C112.909 31.6696 112.863 31.6605 112.817 31.6627V31.6627Z"
            fill="#DA840D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M109.432 32.8897C106.58 34.8826 102.475 34.9636 99.1342 35.6175C90.886 37.2368 82.6629 38.3516 74.2456 38.1523C66.0099 37.9654 57.7681 37.0001 49.5638 36.3275C41.1777 35.6424 32.7729 35.1504 24.3618 35.5303C22.5037 35.5978 20.6604 35.8864 18.8714 36.3898C17.9563 36.6513 16.59 37.0126 16.1324 37.9467C16.0196 38.1892 15.9577 38.452 15.9507 38.719V38.7626C15.537 40.1452 16.0196 41.3036 16.496 42.5305C16.6088 43.0474 16.7341 43.5643 16.8532 44.0812C16.6401 44.0812 16.6088 44.4051 16.8532 44.4113H16.9222C16.948 44.4405 16.9799 44.4638 17.0155 44.4798C17.0512 44.4958 17.0899 44.5041 17.129 44.5041C17.1681 44.5041 17.2068 44.4958 17.2424 44.4798C17.2781 44.4638 17.3099 44.4405 17.3358 44.4113C18.815 44.4113 20.2816 44.1373 21.767 44.0999C23.9983 44.0376 25.8911 45.1711 27.8403 46.1053C31.3376 47.7619 34.6406 49.6988 38.1818 51.1686C39.7618 51.8255 41.3872 52.3687 43.0455 52.7941C44.6989 53.1474 46.3739 53.3929 48.0596 53.529C49.8521 53.7594 51.6258 54.1518 53.4121 54.3946C55.4126 54.6302 57.4298 54.6948 59.4415 54.5877C67.4308 54.4478 75.3957 53.6667 83.2584 52.2522C90.4034 50.9195 97.5485 48.9141 103.772 45.0839C106.233 43.5973 108.317 41.5688 109.864 39.155C110.579 38.0402 111.187 36.8382 111.851 35.6922C112.34 34.839 112.929 33.8239 112.841 32.8336C112.697 30.6788 110.309 32.2856 109.432 32.8897Z"
            fill="#C66720"
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M61.3721 66.0159C30.3475 70.3755 21.059 57.9633 21.341 58.3307C28.9123 68.2954 41.6292 70.4751 62.5379 70.0578C84.8882 69.6157 93.9699 66.9937 100.306 61.4384C103.515 58.6172 107.069 48.2103 107.777 46.1863C92.2526 58.8788 87.8214 62.2792 61.3721 66.0159Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M117.968 21.5113C117.611 19.3253 114.208 19.0201 112.528 18.3973C111.043 17.8493 110.021 16.529 108.811 15.5387C107.506 14.4664 106.054 13.5837 104.499 12.9168C100.958 11.3972 97.4106 10.1827 94.1828 8.03409C91.212 6.04739 87.5642 5.79205 84.1547 5.23776C82.0469 4.91078 79.9839 4.34449 78.0061 3.55C76.2493 2.72956 74.3934 2.1372 72.4844 1.7875C70.5164 1.52593 68.4919 1.97434 66.5239 2.0553C64.3428 2.14249 62.3371 1.43251 60.2876 0.809721C58.6146 0.207628 56.8376 -0.0570251 55.0605 0.0312328C53.4058 0.168247 51.9267 1.04015 50.5353 1.89961C50.5004 1.91888 50.4711 1.94678 50.4502 1.9806C50.4293 2.01442 50.4176 2.05303 50.4162 2.09267C48.843 2.90853 47.3325 3.8178 45.7531 4.63988C44.4351 5.3622 43.0216 5.8968 41.5538 6.228C39.755 6.60168 38.0628 6.30274 36.2765 6.0723C33.1427 5.66749 30.1781 5.59275 27.2073 6.73246C23.6724 8.09015 20.8582 10.8553 17.3546 12.3376C14.3399 13.5832 11.7138 15.0592 9.8335 17.8181C9.05088 18.9761 8.51744 20.2821 8.2666 21.6545C8.0347 23.1305 8.39196 24.4695 8.70534 25.9144C9.119 27.7828 8.74921 29.2775 8.2102 31.0774C7.75088 32.2641 7.7847 33.5836 8.30421 34.7456C8.79308 35.6922 9.8335 36.3026 10.7423 36.7883C11.6511 37.2741 13.9701 37.7973 14.1644 38.9307C14.9917 43.7387 20.432 41.4842 23.6347 42.6675C25.2079 43.2591 26.662 44.156 28.2602 44.7352C30.0966 45.4015 32.0208 45.8998 33.9011 46.4042C37.6616 47.4132 41.2843 48.7584 44.9383 50.141C46.5491 50.7638 48.0721 51.536 49.7957 51.8474C51.5193 52.1588 53.2993 51.8786 55.0041 51.7291C58.5453 51.4115 69.7454 52.3519 71.2309 51.5983C73.155 50.6579 74.9413 50.7451 77.0221 50.9195C78.6454 51.0565 80.0494 49.8607 81.679 49.7299C83.5521 49.5465 85.3995 49.1602 87.1882 48.5778C88.7835 47.9653 90.4088 47.4332 92.0581 46.9834C93.9384 46.5662 95.5805 45.2147 97.4796 44.9594C101.171 44.4549 103.879 42.3997 106.737 40.0144C109.332 37.8533 115.468 30.4047 116 28.9287C116.533 27.4527 116.928 25.8148 117.36 24.2889C117.599 23.4357 118.119 22.4206 117.968 21.5113Z"
            fill="#F0A508"
          />
          <g opacity="0.5">
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M60.0621 10.5316C59.5419 11.2104 58.9152 11.3973 58.9716 15.6198C59.028 19.8423 59.4354 21.4366 57.7995 24.6378C56.1637 27.8389 52.9923 30.9467 51.8704 34.3533C50.7485 37.76 49.332 43.3526 50.7673 45.5262C52.2026 47.6997 57.4297 48.7709 64.556 48.964C71.6822 49.1571 79.4979 47.9987 83.8915 46.641C88.2851 45.2833 90.8235 44.7228 91.82 43.527C92.8166 42.3313 87.8464 32.1175 84.3679 28.1316C80.8893 24.1458 79.2222 22.8255 75.2987 19.5247C71.3751 16.2239 69.6578 14.9098 67.7211 12.2442C65.7844 9.5787 65.283 9.43546 63.2461 9.4666C62.0957 9.46333 60.9764 9.83771 60.0621 10.5316V10.5316Z"
              fill="#E2CAB3"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M20.476 37.3677C23.24 31.0339 26.8877 28.1504 27.9094 26.718C30.7235 22.7757 32.3907 21.0506 36.1199 20.4901C39.8491 19.9296 37.8059 19.9482 42.0428 19.3566C46.2797 18.7649 49.8334 17.8494 52.6977 15.6198C55.562 13.3902 57.0411 11.6527 58.5642 11.1108C60.0872 10.569 62.4375 9.36079 60.7704 11.0672C59.1032 12.7737 61.3282 13.4214 58.1944 18.0487C55.0606 22.6761 53.5689 25.0676 52.4533 28.6362C51.3376 32.2048 50.1844 43.3403 47.6335 44.2184C45.0826 45.0965 38.9967 45.2522 33.3434 43.1659C28.9566 41.4548 24.6617 39.5194 20.476 37.3677V37.3677Z"
              fill="#E2CAB3"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M96.878 40.2138C96.878 40.2138 102.118 38.5883 105.126 35.9913C108.135 33.3943 111.143 30.9716 112.096 27.403C113.048 23.8344 114.126 22.9438 112.961 21.8851C111.795 20.8264 105.559 15.2026 100.425 12.4311C95.2923 9.65972 88.7677 7.90968 82.9639 6.69524C77.1601 5.48079 74.0828 6.19077 71.0555 6.07244C68.0283 5.95411 63.2335 9.42306 63.3025 9.46043C64.9786 11.0621 66.8431 12.4569 68.8556 13.6144C71.5757 15.0344 77.9185 17.4384 81.0147 19.2196C84.1109 21.0007 86.5678 24.9368 87.2823 26.7802C87.9968 28.6237 88.9996 29.1967 90.0213 32.7279C91.0429 36.2591 93.8758 41.9452 93.8758 41.9452L96.878 40.2138Z"
              fill="#E2CAB3"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.9397 18.7587C11.9397 18.7587 9.52038 25.217 10.7739 28.3247C11.6432 30.3632 12.8274 32.2543 14.2837 33.9298C14.2837 33.9298 17.4175 36.9068 19.5548 33.824C21.692 30.7411 26.3238 23.5417 30.0029 20.764C33.682 17.9864 38.2636 17.3885 41.6606 16.9837C45.0576 16.5789 53.0363 15.215 55.2425 13.4276C57.4487 11.6402 63.3026 9.46039 63.3026 9.46039C60.7701 8.30505 58.1319 7.39368 55.4242 6.73879C51.3252 5.80461 44.7693 8.302 39.4481 7.05642C34.127 5.81084 28.9374 8.61962 23.0458 11.609C19.1336 13.6532 15.4162 16.0462 11.9397 18.7587V18.7587Z"
              fill="#E2CAB3"
            />
            <path
              opacity="0.5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M47.0383 5.53683C47.0383 5.53683 52.5475 0.373898 56.2892 0.467317C61.0213 0.591875 62.5568 1.62571 64.8194 2.33569C67.3954 3.13286 68.4233 3.45049 67.7589 4.47809C67.0946 5.50569 63.9608 10.0832 63.9608 10.0832C63.9608 10.0832 56.4396 6.41497 53.6819 5.99147C51.4822 5.67259 49.2612 5.5206 47.0383 5.53683V5.53683Z"
              fill="#E2CAB3"
            />
          </g>
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M101.221 11.2539C99.736 10.6311 98.2443 10.1142 96.7839 9.42287C95.0737 8.55029 93.3161 7.77269 91.5192 7.09363C89.8979 6.59936 88.2341 6.25535 86.549 6.06603C82.7383 5.44323 79.241 3.88003 75.5869 2.75901C73.4919 2.11853 71.3027 1.83634 69.1125 1.92447C67.2071 2.04902 65.2956 2.04902 63.3902 1.92447C61.5099 1.72517 59.8553 0.859494 58.0252 0.379945C55.8002 -0.205478 53.9512 0.379945 51.9769 1.43246C49.0625 2.93339 46.1543 4.76439 42.9955 5.72349C41.3084 6.27025 39.5427 6.53952 37.7683 6.52066C35.888 6.45838 34.0955 6.08471 32.2465 5.96015C30.2097 5.74405 28.1533 6.11596 26.3237 7.03135C24.935 7.76265 23.6265 8.63507 22.419 9.63462C22.4127 9.74455 22.4127 9.85476 22.419 9.9647C22.0545 11.0228 21.9241 12.1465 22.0366 13.2593C22.0918 14.3947 22.391 15.5052 22.9143 16.5161C23.4376 17.527 24.1727 18.4148 25.0701 19.1197C26.3738 20.2158 27.7401 21.0566 28.8307 22.3707C29.9213 23.6848 30.711 25.0549 31.9645 26.1074C34.0077 27.8388 36.7216 27.9758 39.2913 27.9758C40.6451 27.9758 41.9927 27.9073 43.3402 27.9758C45.1897 28.1693 47.0084 28.5876 48.7554 29.2214C52.3655 30.3424 55.9067 31.8932 59.6359 32.6218C63.3651 33.3505 67.063 32.4724 70.6857 31.507C74.3084 30.5417 77.8809 29.894 81.4283 28.9598C84.6356 28.1764 87.6798 26.8399 90.4223 25.0113C93.3492 22.8985 95.9963 20.4273 98.3007 17.6562C99.1844 16.6348 100.024 15.576 100.895 14.5422C101.67 13.7631 102.188 12.7683 102.381 11.6898C101.987 11.564 101.6 11.4185 101.221 11.2539V11.2539Z"
            fill="#E2CAB3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.2122 15.5325C50.9239 15.5947 50.6356 15.6446 50.3661 15.6819H50.2783C48.3103 16.0618 46.3611 16.5352 44.3366 16.3795C42.9703 16.2798 37.6303 16.4231 38.8838 18.8706C38.8986 18.913 38.8986 18.9591 38.8838 19.0014C39.9869 19.6989 42.4376 19.4125 43.2711 19.3502C43.9779 19.3022 44.6798 19.2002 45.3708 19.045C47.3366 18.2703 49.2062 17.2735 50.9427 16.0743C51.0549 15.9056 51.1455 15.7236 51.2122 15.5325Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.9842 20.552C59.1138 19.248 59.0674 17.9327 58.8463 16.6409C58.009 18.6173 56.9358 20.4866 55.6499 22.2086C54.8977 23.149 53.9012 23.9649 53.2932 25.0112C52.9673 25.5717 52.5161 26.4062 52.7542 27.0913C53.1303 28.1687 53.7946 28.1625 54.5029 28.0193C55.7188 27.2719 56.8344 25.5281 57.4361 24.6313C57.9927 23.8005 58.4452 22.9054 58.7836 21.9657C58.8526 21.4986 58.9153 21.0315 58.9842 20.552Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M79.0215 16.8155C77.5519 16.2208 76.0519 15.7032 74.5277 15.2647C73.597 15.0243 72.6874 14.7098 71.8075 14.3243C71.1322 13.9214 70.3689 13.6864 69.5825 13.6393C69.535 13.6362 69.4875 13.6453 69.4446 13.6659C69.4017 13.6864 69.3649 13.7177 69.3378 13.7565C69.3107 13.7954 69.2941 13.8406 69.2898 13.8877C69.2854 13.9348 69.2935 13.9823 69.313 14.0254C69.3753 14.2236 69.4979 14.3977 69.664 14.5236C70.5987 15.8224 71.6339 17.0468 72.7602 18.1856C73.7693 19.3191 74.6405 20.5398 75.7687 21.5923C77.2979 23.0559 80.062 23.9465 81.6289 21.9286C83.3587 19.6803 81.0021 17.6438 79.0215 16.8155Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.7038 5.77956C72.1711 4.63985 70.4412 4.67099 69.2942 4.93879C69.2011 4.95087 69.1089 4.9696 69.0185 4.99484C67.4312 5.80624 66.1997 7.16955 65.5588 8.82501V8.82501C67.439 8.27072 68.8242 6.95663 70.811 6.7262C71.1307 6.68883 73.0673 6.54559 72.7038 5.77956Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M46.1792 7.84704C46.431 7.92678 46.6924 7.97286 46.9564 7.98405C47.5772 7.87848 48.2073 7.83674 48.8367 7.85949C49.081 7.84727 49.3257 7.84727 49.57 7.85949C50.3907 7.89219 51.2089 7.9712 52.0206 8.09615L52.3403 8.18335C52.7866 8.2303 53.2344 8.10795 53.5938 7.84081C51.7574 7.03741 46.6995 5.13167 46.01 7.59169C46.0005 7.64763 46.0126 7.70509 46.044 7.75247C46.0754 7.79986 46.1238 7.83365 46.1792 7.84704V7.84704Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.8018 34.8017C11.655 35.1257 11.5497 35.4668 11.4884 35.8169C11.3946 36.0774 11.3397 36.3502 11.3255 36.6265C11.3284 36.6439 11.3355 36.6603 11.346 36.6745C11.3566 36.6887 11.3704 36.7002 11.3863 36.7081C11.4021 36.716 11.4197 36.72 11.4374 36.7199C11.4552 36.7198 11.4726 36.7155 11.4884 36.7075C11.6765 36.6016 11.6827 36.284 11.7141 36.0847C11.7709 35.6615 11.8674 35.2444 12.0024 34.8391C12.0525 34.7457 11.8582 34.6834 11.8018 34.8017Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9799 35.7669C12.8198 36.0753 12.7038 36.4044 12.6352 36.7446C12.585 36.9253 12.4409 37.2117 12.4973 37.3674C12.5008 37.3876 12.5106 37.4062 12.5251 37.4207C12.5397 37.4352 12.5584 37.4449 12.5787 37.4484C12.8169 37.4484 12.842 37.137 12.8796 36.9689C12.9673 36.6014 13.0426 36.2215 13.1679 35.8603C13.2118 35.7544 13.0426 35.6548 12.9799 35.7669Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.6461 40.0142C18.5897 40.637 18.3891 41.2598 18.3954 41.92C18.3954 42.1068 18.69 42.138 18.6962 41.92C18.7909 41.278 18.8516 40.6314 18.878 39.9831C18.8717 39.8585 18.6586 39.8897 18.6461 40.0142Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.2003 40.3382C19.9954 40.8292 19.8971 41.3577 19.912 41.889C19.912 42.0883 20.2316 42.1256 20.2379 41.889C20.2564 41.3749 20.313 40.8628 20.4071 40.3569C20.4322 40.2573 20.2442 40.2261 20.2003 40.3382Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M45.5278 48.8395C45.4333 49.074 45.366 49.3185 45.3272 49.5681C45.2682 49.7915 45.2429 50.0224 45.252 50.2532C45.2603 50.2781 45.2762 50.2998 45.2976 50.3152C45.319 50.3306 45.3447 50.3389 45.3711 50.3389C45.3975 50.3389 45.4232 50.3306 45.4446 50.3152C45.466 50.2998 45.482 50.2781 45.4902 50.2532C45.5432 50.0498 45.5706 49.8406 45.5717 49.6304C45.6093 49.3688 45.6218 49.1073 45.672 48.8457C45.6845 48.7896 45.5591 48.771 45.5278 48.8395Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M47.3077 49.0574C47.2097 49.372 47.1426 49.6952 47.1072 50.0227C47.0368 50.2971 47.0156 50.5817 47.0445 50.8635C47.0542 50.8877 47.0711 50.9085 47.0929 50.9232C47.1147 50.9379 47.1404 50.9457 47.1667 50.9457C47.193 50.9457 47.2187 50.9379 47.2405 50.9232C47.2623 50.9085 47.2791 50.8877 47.2889 50.8635C47.3558 50.618 47.3854 50.364 47.3767 50.1099C47.3767 49.7611 47.4331 49.4124 47.4644 49.0698C47.4611 49.0524 47.4521 49.0365 47.4388 49.0246C47.4255 49.0128 47.4087 49.0056 47.3908 49.0042C47.373 49.0027 47.3552 49.0072 47.3402 49.0168C47.3252 49.0264 47.3138 49.0407 47.3077 49.0574V49.0574Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M54.3839 50.1223C54.3839 50.0226 54.2648 49.9354 54.2146 50.0289C54.0533 50.3368 53.9674 50.6782 53.9639 51.0253C53.9639 51.1997 53.9201 51.6481 54.1394 51.6917C54.1534 51.6967 54.1683 51.6988 54.1831 51.698C54.1979 51.6972 54.2124 51.6936 54.2258 51.6872C54.2392 51.6808 54.2512 51.6719 54.2611 51.6609C54.271 51.65 54.2787 51.6371 54.2836 51.6232C54.2937 51.4291 54.279 51.2345 54.2397 51.044C54.2371 50.731 54.2858 50.4197 54.3839 50.1223V50.1223Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.1449 50.06C56.1405 50.0461 56.1318 50.0339 56.1199 50.0253C56.1081 50.0166 56.0938 50.012 56.0791 50.012C56.0644 50.012 56.0501 50.0166 56.0382 50.0253C56.0264 50.0339 56.0177 50.0461 56.0133 50.06C55.8747 50.5924 55.8197 51.1428 55.8503 51.6918C55.8503 51.8599 56.1198 51.8786 56.1136 51.6918C56.0841 51.1478 56.0946 50.6025 56.1449 50.06V50.06Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M58.0753 50.2531C58.0753 50.2316 58.0667 50.211 58.0515 50.1958C58.0362 50.1807 58.0154 50.1721 57.9938 50.1721C57.9722 50.1721 57.9515 50.1807 57.9362 50.1958C57.9209 50.211 57.9124 50.2316 57.9124 50.2531C57.8873 50.5022 57.8685 50.7513 57.8559 51.0004C57.8559 51.181 57.7306 51.4177 57.8559 51.5734C57.8658 51.581 57.8779 51.5851 57.8904 51.5851C57.9029 51.5851 57.915 51.581 57.9249 51.5734C58.138 51.5734 58.1255 51.2309 58.138 51.0689C58.1494 50.7955 58.1284 50.5217 58.0753 50.2531V50.2531Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M92.4091 46.3607C92.4091 46.3981 92.4091 46.2985 92.4091 46.286C92.3713 46.197 92.3399 46.1054 92.3151 46.012C92.2525 45.7691 92.2399 45.5075 92.1647 45.2646C92.1647 45.1961 92.0268 45.1587 92.008 45.2646C91.9892 45.3705 92.008 46.7406 92.3966 46.5102C92.4103 46.5036 92.422 46.4936 92.4306 46.4811C92.4393 46.4686 92.4445 46.4541 92.4457 46.439C92.447 46.4239 92.4443 46.4088 92.4379 46.3951C92.4315 46.3813 92.4216 46.3695 92.4091 46.3607V46.3607Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M94.6405 45.8001C94.5162 45.4186 94.3654 45.0461 94.1892 44.6853C94.1809 44.6597 94.1627 44.6384 94.1386 44.6261C94.1145 44.6139 94.0865 44.6116 94.0608 44.6199C94.035 44.6281 94.0136 44.6462 94.0012 44.6702C93.9889 44.6941 93.9866 44.722 93.995 44.7476C94.0884 45.1499 94.2099 45.5453 94.3585 45.9309C94.4274 46.1177 94.7157 45.9869 94.6405 45.8001Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M96.784 44.6976C96.5661 44.3859 96.4186 44.0311 96.3515 43.6575C96.3458 43.6346 96.3325 43.6142 96.3138 43.5996C96.295 43.5851 96.2719 43.5771 96.2481 43.5771C96.2243 43.5771 96.2012 43.5851 96.1825 43.5996C96.1637 43.6142 96.1504 43.6346 96.1447 43.6575C96.1159 44.1041 96.2493 44.5461 96.5207 44.9031C96.6398 45.1024 96.9093 44.8782 96.784 44.6976Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M99.4541 44.3927C99.2434 44.2168 99.0709 44.0003 98.947 43.7562C98.8231 43.5121 98.7504 43.2456 98.7333 42.9727C98.7347 42.9577 98.7315 42.9427 98.7241 42.9295C98.7168 42.9164 98.7056 42.9057 98.692 42.899C98.6785 42.8923 98.6632 42.8898 98.6482 42.8918C98.6332 42.8938 98.6192 42.9003 98.608 42.9104C98.0564 43.2966 98.8837 44.293 99.1908 44.5857C99.3475 44.7601 99.6108 44.5484 99.4541 44.3927Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.475 42.9728C102.144 42.4149 101.777 41.8783 101.378 41.366C101.334 41.2912 101.19 41.3161 101.222 41.4158C101.416 42.0386 101.967 42.4808 102.237 43.0849C102.306 43.253 102.544 43.1409 102.475 42.9728Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M104.969 41.0484C104.913 40.93 104.762 40.8615 104.662 40.7868L104.167 40.4256C104.042 40.3322 103.91 40.245 103.778 40.1578C103.647 40.0706 103.641 40.1204 103.584 40.0893C103.528 40.0581 103.534 40.0893 103.553 40.0893C103.572 40.0893 103.584 40.1889 103.628 40.2325C103.739 40.3468 103.856 40.455 103.979 40.5564C104.136 40.6934 104.299 40.8179 104.462 40.9425C104.625 41.0671 104.712 41.1729 104.856 41.1729C104.872 41.173 104.888 41.1698 104.903 41.1634C104.917 41.157 104.93 41.1476 104.941 41.1358C104.952 41.124 104.96 41.1102 104.965 41.0951C104.969 41.08 104.971 41.0641 104.969 41.0484V41.0484Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M107.094 39.4912C107.083 39.455 107.061 39.4226 107.033 39.3973C107.004 39.3721 106.969 39.3551 106.931 39.3479C106.814 39.2941 106.707 39.2227 106.612 39.1362C106.391 38.9439 106.2 38.7192 106.048 38.4698C105.979 38.3639 105.816 38.4698 105.866 38.5881C106 38.886 106.194 39.1531 106.436 39.3729C106.587 39.5099 106.869 39.7403 107.063 39.6095C107.08 39.5954 107.092 39.5765 107.098 39.5553C107.103 39.5341 107.102 39.5117 107.094 39.4912Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M114.171 31.482C114.142 31.4484 114.106 31.421 114.066 31.4017C114.027 31.3824 113.983 31.3716 113.939 31.3699C113.799 31.346 113.667 31.2929 113.55 31.2142C113.301 31.0429 113.114 30.7961 113.017 30.5105C113.017 30.417 112.854 30.4731 112.861 30.5603C112.899 30.8964 113.068 31.2045 113.331 31.4197C113.512 31.5692 113.957 31.8121 114.164 31.5941C114.178 31.5792 114.187 31.5597 114.188 31.5392C114.189 31.5187 114.183 31.4985 114.171 31.482V31.482Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M115.555 29.894C115.026 29.6218 114.58 29.213 114.264 28.7106C114.208 28.6172 114.045 28.7106 114.101 28.7916C114.39 29.3666 114.861 29.8307 115.443 30.1119C115.568 30.1618 115.681 29.95 115.555 29.894Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M116.414 27.6956C116.174 27.54 115.943 27.3694 115.725 27.1849C115.511 26.9918 115.311 26.7988 115.098 26.6244C115.016 26.5621 114.91 26.6804 114.979 26.7614C115.305 27.2655 115.759 27.6757 116.295 27.9509C116.327 27.959 116.362 27.9556 116.392 27.9412C116.422 27.9269 116.447 27.9025 116.461 27.8722C116.475 27.842 116.478 27.8078 116.469 27.7755C116.461 27.7433 116.441 27.715 116.414 27.6956Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.2474 33.1574C9.70479 33.8963 9.3383 34.7482 9.17567 35.6485C9.17567 35.7233 9.28222 35.7295 9.30103 35.6485C9.51617 34.7934 9.8996 33.9891 10.4292 33.2819C10.5044 33.1636 10.3226 33.039 10.2474 33.1574Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.26973 32.541C9.13184 32.6033 9.07543 32.7216 8.98142 32.8462C8.88741 32.9707 8.72445 33.2074 8.61163 33.4005C8.45175 33.6219 8.36622 33.8877 8.3672 34.1603C8.3672 34.2537 8.51135 34.235 8.51762 34.1603C8.59048 33.8783 8.72295 33.6151 8.90621 33.388C9.05663 33.1576 9.35121 32.896 9.38255 32.622C9.38364 32.6081 9.38113 32.5942 9.37526 32.5816C9.36939 32.569 9.36035 32.5581 9.34902 32.55C9.33769 32.5419 9.32445 32.5368 9.31056 32.5352C9.29667 32.5336 9.28261 32.5356 9.26973 32.541V32.541Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0811 34.565C10.9048 34.8467 10.7618 35.1476 10.6549 35.4619C10.5154 35.7551 10.4143 36.0648 10.354 36.3836C10.354 36.4645 10.4731 36.4895 10.4919 36.3836C10.5723 36.0766 10.6879 35.7799 10.8367 35.4992C11.0197 35.2162 11.1473 34.9014 11.2127 34.5713C11.2135 34.5539 11.2074 34.537 11.1957 34.5241C11.1839 34.5113 11.1675 34.5036 11.15 34.5028C11.1326 34.5019 11.1155 34.508 11.1026 34.5197C11.0897 34.5314 11.0819 34.5477 11.0811 34.565V34.565Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.4779 36.7449C14.0078 36.8881 13.7884 37.5047 13.6631 37.9344C13.6583 37.9565 13.6618 37.9796 13.6729 37.9994C13.684 38.0191 13.702 38.0342 13.7234 38.0418C13.7448 38.0494 13.7683 38.049 13.7895 38.0407C13.8107 38.0324 13.8281 38.0168 13.8386 37.9967C13.9137 37.7791 14.0124 37.5702 14.1331 37.3739C14.2522 37.1871 14.4214 37.0314 14.5468 36.8445C14.5719 36.8072 14.5343 36.7449 14.4779 36.7449Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.5184 38.6131C15.2739 38.7003 15.1423 39.0054 15.0295 39.2359C14.9015 39.5037 14.8433 39.7993 14.8603 40.0953C14.8603 40.1949 15.0107 40.2136 15.017 40.0953C15.038 39.8476 15.1016 39.6054 15.205 39.3791C15.3053 39.1362 15.5497 38.9494 15.6186 38.7065C15.6234 38.6928 15.6241 38.6781 15.6206 38.6641C15.6172 38.65 15.6097 38.6373 15.5991 38.6274C15.5884 38.6175 15.5752 38.6109 15.5608 38.6083C15.5465 38.6058 15.5318 38.6074 15.5184 38.6131Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.5273 39.2669C16.3264 39.5853 16.1743 39.9316 16.0761 40.2945C15.9419 40.6328 15.8411 40.9832 15.7752 41.3408C15.7702 41.3623 15.774 41.3849 15.7858 41.4036C15.7976 41.4222 15.8163 41.4355 15.8379 41.4405C15.8595 41.4454 15.8822 41.4417 15.901 41.43C15.9198 41.4183 15.9332 41.3997 15.9382 41.3782C16.0315 41.0314 16.1509 40.692 16.2954 40.363C16.4724 40.0326 16.5991 39.6779 16.6715 39.3105C16.6744 39.3011 16.6754 39.2912 16.6744 39.2815C16.6734 39.2717 16.6706 39.2622 16.6659 39.2535C16.6612 39.2448 16.6549 39.2371 16.6473 39.2309C16.6396 39.2247 16.6308 39.22 16.6214 39.2171C16.6119 39.2142 16.602 39.2133 16.5921 39.2142C16.5823 39.2152 16.5727 39.218 16.564 39.2227C16.5552 39.2273 16.5475 39.2336 16.5412 39.2412C16.5349 39.2488 16.5302 39.2575 16.5273 39.2669V39.2669Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.4487 40.2697C17.2302 40.7562 17.1396 41.2898 17.1855 41.8205C17.1855 41.9263 17.3484 41.9388 17.3484 41.8205C17.3484 41.3036 17.5803 40.7929 17.549 40.2822C17.5455 40.2723 17.5394 40.2636 17.5312 40.2571C17.523 40.2505 17.5132 40.2464 17.5027 40.2451C17.4923 40.2438 17.4817 40.2454 17.4722 40.2498C17.4626 40.2541 17.4545 40.261 17.4487 40.2697V40.2697Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M49.1315 49.537C48.9098 50.1141 48.8261 50.7345 48.8871 51.3493C48.8871 51.4302 49.0312 51.424 49.0375 51.3493C49.0375 51.0503 49.0813 50.7265 49.119 50.4587C49.1913 50.1631 49.2271 49.8598 49.2255 49.5556C49.2255 49.4934 49.1503 49.4996 49.1315 49.537Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.7673 50.7326C50.7673 50.4461 50.9052 50.1908 50.9303 49.9043C50.9267 49.8948 50.9203 49.8866 50.9119 49.8807C50.9035 49.8749 50.8935 49.8718 50.8832 49.8718C50.873 49.8718 50.863 49.8749 50.8546 49.8807C50.8462 49.8866 50.8398 49.8948 50.8362 49.9043C50.6542 50.1958 50.5479 50.5278 50.5271 50.8703C50.5063 51.2127 50.5716 51.555 50.7172 51.8661C50.761 51.9595 50.8864 51.8661 50.855 51.8038C50.7431 51.4583 50.713 51.0916 50.7673 50.7326V50.7326Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M52.2716 50.9444C52.291 50.6599 52.2657 50.3741 52.1964 50.0974C52.1895 50.0844 52.1791 50.0735 52.1664 50.0658C52.1537 50.0582 52.1392 50.0542 52.1243 50.0542C52.1095 50.0542 52.0949 50.0582 52.0822 50.0658C52.0695 50.0735 52.0592 50.0844 52.0522 50.0974C51.9989 50.3794 51.9989 50.6687 52.0522 50.9506C52.0704 51.2758 52.1272 51.5978 52.2215 51.9097C52.2215 52.0156 52.4158 51.9907 52.3907 51.9097C52.3245 51.5917 52.2847 51.2689 52.2716 50.9444V50.9444Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M108.674 36.7448C108.674 36.6763 108.58 36.6389 108.548 36.6949C108.497 36.7759 108.469 36.8699 108.469 36.9659C108.469 37.0618 108.497 37.1558 108.548 37.2368C108.649 37.4298 108.893 37.7163 109.131 37.6852C109.15 37.6807 109.167 37.6691 109.177 37.6529C109.188 37.6366 109.191 37.617 109.188 37.598C109.125 37.2617 108.592 37.1247 108.674 36.7448Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M110.936 35.7422C110.874 35.6426 110.817 35.6301 110.704 35.5927C110.55 35.5448 110.413 35.454 110.31 35.3312C110.109 35.0961 109.969 34.8161 109.902 34.5153C109.9 34.503 109.895 34.4914 109.888 34.481C109.881 34.4706 109.872 34.4617 109.861 34.4547C109.851 34.4478 109.839 34.443 109.827 34.4405C109.814 34.4381 109.802 34.4381 109.789 34.4406C109.777 34.443 109.765 34.4479 109.755 34.4548C109.744 34.4618 109.735 34.4707 109.728 34.4811C109.721 34.4916 109.717 34.5032 109.714 34.5155C109.712 34.5278 109.712 34.5404 109.714 34.5527C109.771 34.8964 109.911 35.2212 110.122 35.4993C110.278 35.6924 110.692 36.01 110.943 35.8356C110.951 35.8213 110.956 35.8047 110.954 35.7879C110.953 35.7712 110.947 35.7552 110.936 35.7422V35.7422Z"
            fill="#C66720"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.002 33.631C111.807 33.4379 111.6 33.2448 111.419 33.0393C111.344 32.9521 111.187 33.0393 111.243 33.1452C111.385 33.3958 111.558 33.628 111.757 33.8365C111.926 34.0109 112.114 34.2911 112.384 34.3098C112.397 34.3109 112.41 34.309 112.423 34.3043C112.435 34.2996 112.446 34.2921 112.455 34.2826C112.464 34.273 112.471 34.2615 112.475 34.249C112.479 34.2364 112.48 34.2232 112.478 34.2102C112.44 33.9673 112.19 33.8054 112.002 33.631Z"
            fill="#C66720"
          />
          <path
            opacity="0.4"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M66.1981 11.9516C66.1981 13.6643 63.5595 15.0656 60.2941 15.0656C57.0286 15.0656 54.39 13.6767 54.39 11.9516C54.39 10.2265 57.0286 8.83765 60.2941 8.83765C63.5595 8.83765 66.1981 10.2389 66.1981 11.9516Z"
            fill="#DA840D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M98.5262 37.9901C85.2139 34.6333 79.642 42.0009 79.8614 50.6701C79.9491 54.0581 83.0578 58.729 85.1011 62.2603C86.9135 65.0816 89.0275 67.7001 91.4063 70.0701C92.9105 71.135 94.9161 72.3619 96.928 72.3619C97.6993 72.2864 98.4368 72.0092 99.0653 71.5585C99.3598 71.6157 99.6447 71.7143 99.9114 71.8513C100.864 72.1751 101.284 70.8548 102.324 71.3219C103.985 72.0755 105.421 70.5434 106.662 69.7088C107.063 69.441 111.049 63.3003 111.481 62.9765C115.587 57.7388 117.655 56.1943 117.699 49.8356C117.749 41.8888 113.731 34.7703 98.5262 37.9901Z"
            fill="#C73A54"
          />
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M103.572 40.0704C103.572 40.9797 101.228 41.7769 98.2884 41.8516C95.3489 41.9263 92.9484 41.2288 92.9233 40.3382C92.8982 39.4476 95.2674 38.6131 98.2069 38.557C101.146 38.501 103.547 39.1611 103.572 40.0704Z"
              fill="#ED6366"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M90.1405 43.0101C88.9684 41.2476 86.5679 41.7645 85.0825 42.7984L85.0073 42.8544C84.6624 43.0859 84.3504 43.3624 84.0797 43.6765C83.0232 44.7905 82.3533 46.2113 82.1681 47.7309C81.9863 49.5993 82.6194 51.6732 84.2427 52.5015C84.2885 52.5247 84.3258 52.5618 84.3492 52.6073C84.4114 52.5944 84.4723 52.5756 84.531 52.5513C85.446 52.1776 85.4022 50.6829 85.4084 49.9231C85.4084 48.2353 86.8186 47.488 88.1098 46.7656C89.2943 46.0867 91.1683 44.5609 90.1405 43.0101Z"
            fill="#ED6366"
          />
          <g style={{ mixBlendMode: "multiply" }}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M111.35 57.3964C110.247 55.9827 108.592 57.7078 107.802 58.5797C105.966 60.6038 104.819 63.1012 102.65 64.8574C100.482 66.6137 96.5457 65.337 94.8597 67.8904C94.7809 68.0134 94.7273 68.1507 94.702 68.2944C94.6766 68.438 94.6801 68.5852 94.7121 68.7275C94.7441 68.8698 94.8041 69.0045 94.8886 69.1237C94.9731 69.243 95.0804 69.3445 95.2044 69.4225C95.3797 69.4907 95.5629 69.5367 95.7497 69.5595C96.1396 69.7769 96.5633 69.9285 97.0032 70.0079C98.3781 70.1864 99.7745 69.9246 100.989 69.2605C102.092 68.7374 103.34 69.3477 104.437 68.8308C104.842 68.6112 105.191 68.3023 105.458 67.9278C105.81 67.5226 106.105 67.0723 106.336 66.5888C106.887 65.6815 107.516 64.8231 108.216 64.0229C109.066 63.0639 109.836 62.037 110.516 60.9525C111.331 59.7879 112.071 58.3306 111.35 57.3964Z"
              fill="#ED6366"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M110.999 26.1574C110.259 25.9083 108.899 27.0293 108.323 27.3718C107.007 28.1565 105.672 28.9475 104.406 29.8069C102.482 31.1472 100.889 32.9013 99.7424 34.9387C99.2645 35.8063 98.9442 36.7508 98.796 37.7288C98.6014 38.2423 98.5037 38.787 98.5077 39.3356C98.5043 39.3741 98.5086 39.4129 98.5203 39.4497C98.532 39.4865 98.5509 39.5207 98.5759 39.5502C98.601 39.5797 98.6317 39.604 98.6662 39.6217C98.7008 39.6394 98.7385 39.6501 98.7772 39.6532C101.986 39.6906 105.27 37.6043 107.514 35.5055C108.69 34.4755 109.621 33.1993 110.241 31.7687C110.55 30.9619 110.78 30.1278 110.93 29.2776C111.106 28.3683 110.842 27.2659 111.137 26.4252C111.153 26.3715 111.148 26.3137 111.122 26.2638C111.096 26.214 111.052 26.1759 110.999 26.1574V26.1574Z"
            fill="#8DB54F"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M97.9684 39.541C97.9684 38.6504 98.2379 38.0961 98.4949 35.2935C98.7519 32.491 98.7018 31.0897 98.8898 30.6288C99.0778 30.168 100.582 31.1457 100.582 31.1457C100.582 31.1457 100.099 33.2944 99.7422 35.0569C99.3849 36.8194 99.0277 39.9894 99.0277 39.9894C99.0277 39.9894 98.8334 40.5063 98.3633 40.3755C97.8932 40.2447 97.9684 39.541 97.9684 39.541Z"
            fill="#300D33"
          />
        </g>
        <defs>
          <clipPath id="clip0_8_7061">
            <rect width="118" height="74" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
